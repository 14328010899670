import { useState } from "react";

import { currencyFormat } from "@/utils/currency-format";
import { dateToTime, formatDate } from "@/utils/date";
import { If } from "@/components/atoms/if/if";
import { Button } from "@/components/atoms/button/button";
import { Popover } from "@/components/organisms/popover/popover";
import { Loading } from "@/components/atoms/loading";
import { useSynchronizeInvoice } from "./use-synchronize-invoice";
import { INVOICE_STATUSES } from "@/constants/invoice-statuses";
import { useInvoiceCancellation } from "./use-invoice-cancellation";
import { useSynchronizeInvoiceCancellation } from "./use-synchronize-invoice-cancellation";
import { PdfViewer } from "@/components/organisms/pdf-viewer/pdf-viewer";
import { InvoiceSendWhatsapp } from "./invoice-send-whatsapp";

import { Invoice } from "@/interfaces/invoice";

const API_URL = import.meta.env.VITE_APP_API_IP?.replace(/graphql$/, "");

interface InvoiceInfoProps {
	invoice: Invoice;
	setInvoice: (invoice: Invoice) => void;
}

export function InvoiceInfo({ invoice, setInvoice }: InvoiceInfoProps) {
	const { synchronizeInvoice, loading: l1 } = useSynchronizeInvoice();
	const { synchronizeCancellation, loading: l2 } =
		useSynchronizeInvoiceCancellation();
	const { createCancellation, loading: l3 } = useInvoiceCancellation();
	const [showModal, setShowModal] = useState(false);
	const [whatsappModal, setWhatsappModal] = useState(false);

	const sincronizeInvoice = async () => {
		const invoiceResponse = await synchronizeInvoice(invoice.id);
		if (!invoiceResponse) return;

		setInvoice(invoiceResponse);
	};

	const synchronizeInvoiceCancellation = async () => {
		const invoiceResponse = await synchronizeCancellation(invoice.id);
		if (!invoiceResponse) return;
		setInvoice({
			...invoice,
			cancellation: invoiceResponse,
		});
	};

	const cancelInvoice = async () => {
		const cancellation = await createCancellation(
			invoice.id,
			"Error en el documento.",
		);
		if (!cancellation) return;

		setInvoice({
			...invoice,
			isCancelled: true,
			cancellation,
		});
	};

	const sunatResponse = invoice.isCancelled
		? {
				accepted: invoice.cancellation?.isAcceptedBySunat,
				description: invoice.cancellation?.sunatDescription,
				note: invoice.cancellation?.sunatNote,
				responseCode: invoice.cancellation?.sunatResponseCode,
				soapError: invoice.cancellation?.sunatSoapError,
			}
		: {
				accepted: invoice.isAcceptedBySunat,
				description: invoice.sunatDescription,
				note: invoice.sunatNote,
				responseCode: invoice.sunatResponseCode,
				soapError: invoice.sunatSoapError,
			};
	const documents = invoice.isCancelled
		? {
				xml: invoice.cancellation!.xmlDocumentUrl,
				cdr: invoice.cancellation!.cdrDocumentUrl,
				pdf: invoice.cancellation!.pdfDocumentUrl,
			}
		: {
				xml: invoice.xmlDocumentUrl,
				cdr: invoice.cdrDocumentUrl,
				pdf: invoice.pdfDocumentUrl,
			};
	return (
		<>
			<If condition={l1 || l2 || l3} children={<Loading />} />
			<PdfViewer
				fileName={`${invoice.type === "INVOICE" ? "FACTURA" : "BOLETA"}-${invoice.customer.fullName}`}
				showModal={showModal}
				setShowModal={setShowModal}
				fileUrl={`${API_URL}invoice/pdf?pdfUrl=${documents.pdf!}`}
			/>
			<div className="row">
				<div className="col-6 col-md-4 mb-3">
					<p className="small text-muted mb-1">Cliente</p>
					<p className="fw-bold mb-0">{invoice.customer.fullName}</p>
				</div>
				<div className="col-6 col-md-4 mb-3">
					<p className="small text-muted mb-1">Dirección</p>
					<p className="fw-bold mb-0">{invoice.customer.address || "-"}</p>
				</div>
				<div className="col-6 col-md-4 mb-3">
					<p className="small text-muted mb-1">Fecha</p>
					<p className="fw-bold mb-0">{`${formatDate({ date: invoice.date })} ${dateToTime(invoice.date)}`}</p>
				</div>
				<div className="col-6 col-md-4 mb-3">
					<p className="small text-muted mb-1">{`Número de ${invoice.type === "INVOICE" ? "Factura" : "Boleta"}`}</p>
					<p className="fw-bold mb-0">{invoice.completeNumber}</p>
				</div>
				<div className="col-6 col-md-4 mb-3">
					<p className="small text-muted mb-1">Estado</p>
					<p className="fw-bold mb-0">
						{`${INVOICE_STATUSES[invoice.status as keyof typeof INVOICE_STATUSES]} ${invoice.isCancelled ? "(ANULADO)" : ""}`}
					</p>
				</div>
				<div className="col-6 col-md-4 mb-3">
					<p className="small text-muted mb-1">Total</p>
					<p className="fw-bold mb-0">{currencyFormat(invoice.total)}</p>
				</div>
				<div className="col-6 col-md-4 mb-3">
					<p className="small text-muted mb-1">{`Respuesta SUNAT ${invoice.isCancelled ? "anulación" : ""}`}</p>
					<Popover
						control={
							<p className="fw-bold mb-0">
								<Button size="small" mode="light" popoverTarget="my-popover">
									Ver
								</Button>
							</p>
						}
						id="my-popover"
					>
						<p className="mb-0">
							Aceptado por SUNAT: {sunatResponse.accepted ? "SI" : "NO"}
						</p>
						<p className="mb-0">
							Sunat descripción: {sunatResponse.description}
						</p>
						<p className="mb-0">Sunat nota: {sunatResponse.note}</p>
						<p className="mb-0">
							Sunat código respuesta:{sunatResponse.responseCode}
						</p>
						<p className="mb-0">Sunat SOAP error: {sunatResponse.soapError}</p>
					</Popover>
				</div>
				<div className="col-6 col-md-4 mb-3">
					<p className="small text-muted mb-1">{`Documentos ${invoice.isCancelled ? "de anulación" : ""}`}</p>
					<p className="fw-bold mb-0">
						<If condition={!!documents.xml}>
							<a
								href={documents.xml}
								className="btn btn-success btn-sm"
								target="_blank"
							>
								XML
							</a>
						</If>
						<If condition={!!documents.cdr}>
							<a
								href={documents.cdr ?? undefined}
								className="btn btn-success btn-sm ms-1"
								target="_blank"
							>
								CDR
							</a>
						</If>
						<If condition={!!documents.pdf}>
							<Button
								size="small"
								mode="danger"
								className="ms-1"
								onClick={() => setShowModal(true)}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="16"
									height="16"
									fill="currentColor"
									className="me-1 mb-1"
									viewBox="0 0 16 16"
								>
									<path
										fillRule="evenodd"
										d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z"
									/>
								</svg>
								PDF
							</Button>
						</If>
					</p>
				</div>
				<div className="col-6 col-md-4 mb-3">
					<p className="small text-muted mb-1">{`Acciones ${invoice.isCancelled ? "anulación" : "comprobante"}`}</p>
					<p className="fw-bold mb-0">
						<If condition={invoice.status === 2 && !sunatResponse.accepted}>
							<Button
								size="small"
								mode="secondary"
								onClick={
									invoice.isCancelled
										? synchronizeInvoiceCancellation
										: sincronizeInvoice
								}
							>
								Sincronizar
							</Button>
						</If>
						<If condition={invoice.status === 2 && !invoice.isCancelled}>
							<Button
								size="small"
								mode="danger"
								className="ms-2"
								onClick={cancelInvoice}
							>
								Anular
							</Button>
						</If>
						<If condition={!invoice.isCancelled}>
							<Button
								size="small"
								mode="success"
								className="ms-2"
								onClick={() => setWhatsappModal(true)}
							>
								WhatsApp
							</Button>
						</If>
					</p>
				</div>
			</div>
			<InvoiceSendWhatsapp
				showModal={whatsappModal}
				closeModal={() => setWhatsappModal(false)}
				documents={documents}
				whatsappServerUrl={invoice.whatsappServerUrl}
				invoiceType={invoice.type}
			/>
		</>
	);
}
