import { gql } from "@apollo/client";

export const SAVE_ORDER = gql`
	mutation ($order: OrderInput!) {
		saveOrder(order: $order) {
			id
			number
			status
			date
			total
			observations
			clientName
			type
			paymentStatus
			products {
				id
				quantity
				productPrice
				productSubTotal
				product {
					id
					name: displayName
				}
				details {
					id
					discountRate
					discount
					delivered
					options
				}
			}
			tables {
				id
				number
			}
			payments {
				id
				amount
				date
				transactionMethod
				status
				orderProductDetails {
					id
					amount
					units
					orderProductId
				}
			}
		}
	}
`;

export const RELEASE_ORDER = gql`
	mutation releaseOrder($orderId: ID!) {
		releaseOrder(orderId: $orderId)
	}
`;

export const PAY_ORDER = gql`
	mutation payOrder($orderId: ID!, $employeeId: ID!) {
		payOrder(orderId: $orderId, employeeId: $employeeId)
	}
`;

export const CANCEL_ORDER_PAYMENT = gql`
	mutation cancelOrderPayment($orderPaymentId: ID!) {
		cancelOrderPayment(orderPaymentId: $orderPaymentId)
	}
`;

export const CREATE_INVOICE = gql`
	mutation createInvoice(
		$orderPaymentIds: [ID!]!
		$customerId: ID!
		$details: [InvoiceDetailInput!]
		$type: InvoiceTypeEnum!
		$email: String
		$date: Date!
	) {
		createInvoice(
			type: $type
			customerId: $customerId
			orderPaymentIds: $orderPaymentIds
			details: $details
			email: $email
			date: $date
		) {
			id
		}
	}
`;

export const SYNCHRONIZE_INVOICE = gql`
	mutation synchronizeInvoice($id: ID!) {
		synchronizeInvoice(id: $id) {
			id
			serie
			number
			completeNumber
			pdfDocumentUrl
			cdrDocumentUrl
			xmlDocumentUrl
			date
			type
			currency
			total
			status
			taxPercentage
			isAcceptedBySunat
			sunatDescription
			sunatNote
			sunatResponseCode
			sunatSoapError
			whatsappServerUrl
			customer {
				id
				fullName
				displayName
				documentType
				address
			}
			details {
				code
				discount
				name
				units
				unitPrice
				total
				measurementUnit
			}
			orderPayments {
				id
				date
				status
				amount
				transactionMethod
				orderId
			}
		}
	}
`;

export const CREATE_CUSTOMER = gql`
	mutation createCustomer($customer: CustomerInput!) {
		createCustomer(customer: $customer) {
			id
			fullName
			address
			phoneNumber
			documentType
			documentNumber
			email
		}
	}
`;

export const UPDATE_CUSTOMER = gql`
	mutation updateCustomer($customer: CustomerInput!) {
		updateCustomer(customer: $customer) {
			id
			fullName
			address
			phoneNumber
			documentType
			documentNumber
			email
		}
	}
`;

export const CREATE_INVOICE_CANCELLATION = gql`
	mutation createInvoiceCancellation($invoiceId: ID!, $reason: String!) {
		createInvoiceCancellation(invoiceId: $invoiceId, reason: $reason) {
			id
			reason
			number
			date
			sunatTicketNumber
			isAcceptedBySunat
			sunatDescription
			sunatNote
			sunatResponseCode
			sunatSoapError
			pdfDocumentUrl
			cdrDocumentUrl
			xmlDocumentUrl
		}
	}
`;

export const SYNCHRONIZE_INVOICE_CANCELLATION = gql`
	mutation synchronizeInvoiceCancellation($invoiceId: ID!) {
		synchronizeInvoiceCancellation(invoiceId: $invoiceId) {
			id
			reason
			number
			date
			sunatTicketNumber
			isAcceptedBySunat
			sunatDescription
			sunatNote
			sunatResponseCode
			sunatSoapError
			pdfDocumentUrl
			cdrDocumentUrl
			xmlDocumentUrl
		}
	}
`;

export const CREATE_MONEY_MOVEMENT = gql`
	mutation createMoneyMovement($moneyMovement: MoneyMovementInput!) {
		createMoneyMovement(moneyMovement: $moneyMovement)
	}
`;

export const CANCEL_MONEY_MOVEMENT = gql`
	mutation cancelMoneyMovement($moneyMovementId: ID!) {
		cancelMoneyMovement(moneyMovementId: $moneyMovementId)
	}
`;
