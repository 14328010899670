import { useCallback, useContext } from "react";
import { AuthDispatchContext } from "./auth";
import { AuthReducerType } from "../interfaces/auth-reducer-type.enum";
import { AuthState } from "../interfaces/auth-state.interface";

interface LoginPayload extends Omit<AuthState, "auth"> {}

export const useAuthMutations = () => {
	const dispatch = useContext(AuthDispatchContext);

	const login = useCallback(
		(payload: LoginPayload) =>
			dispatch({
				type: AuthReducerType.LOGIN,
				item: payload,
			}),
		[dispatch],
	);

	const updateToken = useCallback(
		(token: string) =>
			dispatch({
				type: AuthReducerType.UPDATE_TOKEN,
				item: token,
			}),
		[dispatch],
	);

	const logout = useCallback(
		() =>
			dispatch({
				type: AuthReducerType.LOGOUT,
				item: null,
			}),
		[dispatch],
	);

	return {
		login,
		updateToken,
		logout,
	};
};
