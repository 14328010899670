import { useState } from "react";
import { useQuery } from "@apollo/client";
import { Link, useSearchParams } from "react-router-dom";

import Loading from "../components/atoms/loading";
import { GET_ORDERS } from "../graphql";
import { formatDate } from "../utils/date";
import Layout from "./layout";
import { dateToTime } from "../utils/date";
import { orderResponseToOrder } from "@/utils/order-response-to-order";

import { WithAuthenticationProps } from "../hoc/withAuthentication";
import { Order } from "../interfaces/order.interface";
import { OrderPaymentStatus } from "@/interfaces/order-payment-status.enum";
import { OrderStatus } from "@/interfaces/order-status.enum";
import { OrderResponse } from "@/interfaces/order-response.interface";
import { currencyFormat } from "@/utils/currency-format";
import { If } from "@/components/atoms/if/if";
import { UserRole } from "@/interfaces/user-role.enum";

interface OrdersPageProps extends WithAuthenticationProps {}

const TODAY = formatDate({ format: "en" });

function getInitialDate(paramDate: string | null, role: UserRole): string {
	if (![UserRole.ADMIN].includes(role)) return TODAY;

	if (!paramDate) return TODAY;

	const date = new Date(paramDate);
	if (date.toString() === "Invalid Date") return TODAY;

	return paramDate;
}

const OrdersPage: React.FC<OrdersPageProps> = ({ role }) => {
	const [searchParams, setUrlSearchParams] = useSearchParams();
	const [orders, setOrders] = useState<Order[]>([]);
	const [date, setDate] = useState(
		getInitialDate(searchParams.get("date"), role),
	);
	const [orderStatus, setOrderStatus] = useState<OrderStatus | "ALL">("ALL");

	const { loading: l1 } = useQuery(GET_ORDERS, {
		fetchPolicy: "no-cache",
		variables: {
			date: date,
			statusCode: orderStatus === "ALL" ? undefined : orderStatus,
		},
		onCompleted: ({ orders: responseOrders }: { orders: OrderResponse[] }) => {
			const orders = responseOrders.map((o) => orderResponseToOrder(o));

			setOrders(orders);
		},
	});

	return (
		<Layout>
			{l1 && <Loading />}
			<div className="row mt-2 g-2">
				<If condition={[UserRole.ADMIN].includes(role)}>
					<div className="form-floating mb-3 col-6 col-sm-4">
						<input
							id="inputDate"
							className="form-control"
							type="date"
							value={date}
							onChange={(e) => {
								setUrlSearchParams({ date: e.target.value });
								setDate(e.target.value);
							}}
						/>
						<label htmlFor="inputDate">Fecha</label>
					</div>
				</If>
				<div className="form-floating mb-3 col-6 col-sm-4">
					<select
						className="form-select"
						id="formOrderStatus"
						aria-label="Floating label select example"
						value={orderStatus}
						onChange={(e) => setOrderStatus(e.target.value as OrderStatus)}
					>
						<option value="ALL">Todos</option>
						<option value={OrderStatus.ATENDED}>Atendido</option>
						<option value={OrderStatus.REGISTERED}>Registrado</option>
						<option value={OrderStatus.ANULATED}>Anulado</option>
					</select>
					<label htmlFor="formOrderStatus">Estado</label>
				</div>
			</div>
			<div className="list-group">
				{orders.length === 0 && <p>No hay órdenes por pagar</p>}
				{orders.map((o) => {
					const ta = o.tables.map((t) => t.number).join(" - ");
					return (
						<li key={o.id} className="list-group-item">
							<div className="row">
								<div className="col-6 col-sm-5">
									<h5 className="mb-0">
										Cliente:{" "}
										{o.tables.length === 0 ? o.clientName : "Mesa " + ta}
									</h5>
									<p className="mb-0">Hora: {dateToTime(o.date)}</p>
									<p className="mb-0">{getOrderStatusBadge(o.status)}</p>
								</div>
								<div className="col-6 col-sm-5">
									<div className="d-flex flex-column align-items-end align-items-sm-center">
										<p className="mb-0">
											<small>Pago</small>
										</p>
										<p className="mb-0">{paymentStatus(o.paymentStatus)}</p>
										<p className="mb-0">Total: {currencyFormat(o.total)}</p>
									</div>
								</div>
								<div className="col-12 col-sm-2">
									<div className="d-flex justify-content-end justify-content-sm-center">
										<Link className="btn btn-secondary" to={`/order/${o.id}`}>
											Ver
										</Link>
									</div>
								</div>
							</div>
						</li>
					);
				})}
			</div>
		</Layout>
	);
};

function paymentStatus(status: OrderPaymentStatus) {
	let classes = "badge rounded-pill";
	switch (status) {
		case OrderPaymentStatus.PENDING:
			classes += " bg-warning text-dark";
			break;
		case OrderPaymentStatus.PAID:
			classes += " bg-success";
			break;

		default:
			classes += " bg-secondary";
			break;
	}

	return <span className={classes}>{status}</span>;
}

function getOrderStatusBadge(status: OrderStatus) {
	let classes = "badge rounded-pill";
	let statusText = "";

	switch (status) {
		case OrderStatus.ANULATED:
			classes += " bg-danger";
			statusText = "ANULADO";
			break;
		case OrderStatus.REGISTERED:
			classes += " bg-success";
			statusText = "REGISTRADO";
			break;
		case OrderStatus.ATENDED:
			classes += " bg-success";
			statusText = "REGISTRADO";
			break;

		default:
			classes += " bg-secondary";
			statusText = "POR REGISTRAR";
			break;
	}

	return <span className={classes}>{statusText}</span>;
}

export default OrdersPage;
