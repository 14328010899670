import { useRef, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { SelectInstance } from "react-select";
import AsyncSelect from "react-select/async";

import { Button } from "@/components/atoms/button/button";
import { If } from "@/components/atoms/if/if";
import { Loading } from "@/components/atoms/loading";
import { Checkbox } from "@/components/organisms/checkbox";
import { CustomerForm } from "@/components/organisms/customer/customer-form";
import { Modal } from "@/components/organisms/modal/modal";
import { Input } from "@/components/atoms/input/input";
import { Radio } from "@/components/organisms/radio";
import { FIND_CUSTOMERS } from "@/graphql/queries";
import { SERVICES } from "@/constants/services";

import { Customer } from "@/interfaces/customer";
import { Service } from "@/interfaces/service";
import { InvoiceType } from "@/interfaces/invoice";

interface InvoiceCustomerProps {
	services: Service[];
	onChangeService: (service: Service[]) => void;
	handleCreateInvoice: (customer: Customer, invoiceType: InvoiceType) => void;
}

type FindCustomerQuery = { findCustomers: Customer[] };
type CustomersSelect = SelectInstance<{ label: string; value: string }>;

export function InvoiceCustomer({
	onChangeService,
	handleCreateInvoice,
	services,
}: InvoiceCustomerProps) {
	const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
	const [invoiceType, setInvoiceType] = useState<InvoiceType>();
	const [load, { loading }] = useLazyQuery<FindCustomerQuery>(FIND_CUSTOMERS);
	const [customers, setCustomers] = useState<Customer[]>([]);
	const selectRef = useRef<CustomersSelect>(null);
	const [showCustomerModal, setShowCustomerModal] = useState(false);

	const promiseOptions = async (searchTerm: string) => {
		if (searchTerm.length < 3) {
			setCustomers([]);
			return Promise.resolve([]);
		}

		const { data } = await load({
			variables: {
				searchTerm,
				invoiceType,
				limit: 10,
			},
		});
		if (!data) return [];
		setCustomers(data.findCustomers);

		return data.findCustomers.map((customer) => ({
			value: customer.id,
			label: customer.displayName,
		}));
	};

	const handleClientChange = (customerId: string | undefined) => {
		if (!customerId) {
			setCustomers([]);
			setSelectedCustomer(undefined);
			return;
		}

		const selected = customers.find((customer) => customer.id === customerId);
		setSelectedCustomer(selected);
	};

	const handleInvoiceTypeChange = (type: InvoiceType) => {
		setInvoiceType(type);
		selectRef.current?.clearValue();
	};

	const handleServiceChange = (service: Service) => {
		const prev = [...services];
		const byConsumeCode = SERVICES[0].code;
		const serviceIndex = prev.findIndex((s) => s.code === service.code);

		if (serviceIndex === -1 && service.code === byConsumeCode) {
			onChangeService([service]);
			return;
		}

		if (serviceIndex === -1) {
			prev.push(service);
		} else {
			prev.splice(serviceIndex, 1);
		}
		onChangeService(prev.filter((s) => s.code !== byConsumeCode));
	};

	const showCustomerButton =
		customers.length === 0 && selectedCustomer === undefined;
	const invoiceTypeSelected = invoiceType !== undefined;
	return (
		<div className="card mt-2">
			<If condition={false} children={<Loading />} />
			<div className="card-body">
				<form className="row g-3" onSubmit={(e) => e.preventDefault()}>
					<div className="col-md-12">
						<div className="form-check form-check-inline">
							<Radio
								label="Factura"
								checked={invoiceType === "INVOICE"}
								onChange={() => handleInvoiceTypeChange("INVOICE")}
							/>
						</div>
						<div className="form-check form-check-inline">
							<Radio
								label="Boleta"
								checked={invoiceType === "RECEIPT"}
								onChange={() => handleInvoiceTypeChange("RECEIPT")}
							/>
						</div>
					</div>
					<div className="col-md-8">
						<label className="form-label">Cliente</label>
						<div className="d-flex">
							<AsyncSelect
								ref={selectRef}
								isDisabled={!invoiceTypeSelected}
								className="w-100"
								styles={{
									control: (baseStyles) => ({
										...baseStyles,
										...(showCustomerButton && {
											borderTopRightRadius: 0,
											borderBottomRightRadius: 0,
										}),
									}),
								}}
								defaultOptions
								isClearable
								loadOptions={promiseOptions}
								placeholder="Buscar cliente"
								noOptionsMessage={(value) => {
									if (invoiceType === undefined)
										return "Seleccione el tipo de comprobante";

									if (value.inputValue.length < 3)
										return "Ingrese al menos 3 caracteres";

									return `No se encontraron resultados para ${value.inputValue}`;
								}}
								isLoading={loading}
								onChange={(e) => handleClientChange(e?.value)}
							/>
							<If condition={showCustomerButton}>
								<Button
									disabled={!invoiceTypeSelected}
									mode="secondary"
									style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
									onClick={() => setShowCustomerModal(true)}
								>
									Agregar
								</Button>
							</If>
						</div>
					</div>
					<div className="col-md-4 mb-3">
						<Input
							label="Email"
							type="email"
							disabled={!invoiceTypeSelected}
							value={selectedCustomer?.email ?? ""}
							onChange={(e) => {
								setSelectedCustomer((prev) => {
									if (!prev) return prev;
									return { ...prev, email: e.target.value };
								});
							}}
							placeholder="usuario@ejemplo.com"
						/>
					</div>
					<If condition={!!selectedCustomer}>
						<div className="col-md-12">
							<label className="form-label">Datos</label>
							<p className="mb-0">
								<Button
									className="me-1"
									size="small"
									mode="secondary"
									onClick={() => setShowCustomerModal(true)}
								>
									Editar
								</Button>
								{`${selectedCustomer?.displayName} - ${selectedCustomer?.address ?? ""}`}
							</p>
						</div>
					</If>
					<div className="col-md-8">
						{SERVICES.map(({ code, name, measurementUnit }) => (
							<div key={code} className="form-check form-check-inline">
								<Checkbox
									label={name}
									value={code}
									checked={!!services.find(({ code: c }) => c === code)}
									onChange={() =>
										handleServiceChange({ code, name, measurementUnit })
									}
								/>
							</div>
						))}
					</div>
					<div className="col-md-12 text-end">
						<Button
							type="submit"
							disabled={!invoiceTypeSelected || !selectedCustomer}
							onClick={() => {
								handleCreateInvoice(selectedCustomer!, invoiceType!);
							}}
						>
							Enviar
						</Button>
					</div>
				</form>
				<Modal show={showCustomerModal}>
					<CustomerForm
						customer={showCustomerModal ? selectedCustomer : undefined}
						isInvoiceCustomer={
							showCustomerModal ? invoiceType === "INVOICE" : undefined
						}
						onCancelModal={() => setShowCustomerModal(false)}
						onCustomerCreated={(customer) => {
							selectRef.current?.setValue(
								{ label: customer.displayName, value: customer.id },
								"select-option",
							);
							setSelectedCustomer(customer);
							setShowCustomerModal(false);
						}}
					/>
				</Modal>
			</div>
		</div>
	);
}
