import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { SYNCHRONIZE_INVOICE } from "@/graphql/mutations";
import { useConfirmDialog } from "@/hooks/use-confirm-dialog";
import { invoiceResponseToInvoice } from "./invoice-response-to-invoice";

import { InvoiceResponse } from "@/interfaces/invoice";

export function useSynchronizeInvoice() {
	const { confirm } = useConfirmDialog();
	const [synchronize, { loading }] = useMutation<{
		synchronizeInvoice: InvoiceResponse;
	}>(SYNCHRONIZE_INVOICE);

	const synchronizeInvoice = useCallback(
		async (invoiceId: string) => {
			const resp = await confirm({
				title: "Sincronizar",
				content: "¿Estás seguro de sincronizar el documento?",
			});
			if (!resp) return;
			try {
				const { data } = await synchronize({
					variables: {
						id: invoiceId,
					},
				});

				if (!data?.synchronizeInvoice)
					throw new Error(`No se ha podido sincronizar.`);

				return invoiceResponseToInvoice(data.synchronizeInvoice);
			} catch (error: unknown) {
				await confirm({
					title: "Error",
					content: "Ha ocurrido un error al sincronizar",
					showCancelButton: false,
				});
			}
		},
		[synchronize, confirm],
	);

	return { synchronizeInvoice, loading };
}
