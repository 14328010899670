import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { SYNCHRONIZE_INVOICE_CANCELLATION } from "@/graphql/mutations";
import { useConfirmDialog } from "@/hooks/use-confirm-dialog";

import {
	InvoiceCancellation,
	InvoiceCancellationResponse,
} from "@/interfaces/invoice-cancellation.interface";

export function useSynchronizeInvoiceCancellation() {
	const { confirm } = useConfirmDialog();
	const [synchronize, { loading }] = useMutation<{
		synchronizeInvoiceCancellation: InvoiceCancellationResponse;
	}>(SYNCHRONIZE_INVOICE_CANCELLATION);

	const synchronizeCancellation = useCallback(
		async (invoiceId: string): Promise<InvoiceCancellation | undefined> => {
			const resp = await confirm({
				title: "Actualizar",
				content: "¿Estás seguro de sincronizar el documento?",
			});
			if (!resp) return;
			try {
				const { data } = await synchronize({
					variables: {
						invoiceId,
					},
				});

				if (!data?.synchronizeInvoiceCancellation)
					throw new Error(`No se ha podido sincronizar.`);

				await confirm({
					type: "success",
					title: "Sincronización exitosa",
					content: "",
					confirmLabel: "Listo",
					showCancelButton: false,
				});

				return {
					...data.synchronizeInvoiceCancellation,
					date: new Date(data.synchronizeInvoiceCancellation.date),
				};
			} catch (error: unknown) {
				await confirm({
					title: "Error",
					content: "Ha ocurrido un error al sincronizar",
					showCancelButton: false,
				});
			}
		},
		[synchronize, confirm],
	);

	return { synchronizeCancellation, loading };
}
