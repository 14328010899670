import { ApolloClient, InMemoryCache, HttpLink, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { onError } from "@apollo/client/link/error";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import * as Sentry from "@sentry/react";
import { authService } from "../services/firebase";

const httpLink = new HttpLink({
	uri: import.meta.env.VITE_APP_API_IP,
});

const wsLink = new GraphQLWsLink(
	createClient({
		url: import.meta.env.VITE_APP_WS_IP,
	}),
);

const errorLink = onError(
	({ graphQLErrors, networkError, operation, forward }) => {
		if (graphQLErrors) Sentry.captureException(graphQLErrors);
		if (networkError) Sentry.captureException(networkError);

		const isTokenExpired = graphQLErrors?.some(
			({ extensions }) => extensions?.number === "authorization",
		);
		if (isTokenExpired) {
			operation.setContext({
				token: authService.getNewToken(),
			});

			return forward(operation);
		}
	},
);

const authLink = setContext(async (_, { headers, token }) => {
	if (token instanceof Promise) {
		token = await token;
	}

	return {
		headers: {
			...headers,
			authorization: `Bearer ${token}`,
		},
	};
});

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === "OperationDefinition" &&
			definition.operation === "subscription"
		);
	},
	wsLink,
	authLink.concat(httpLink),
);

export const client = new ApolloClient({
	link: errorLink.concat(splitLink),
	cache: new InMemoryCache(),
});
