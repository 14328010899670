import { useNavigate } from "react-router-dom";

import { Button } from "@/components/atoms/button/button";
import { If } from "@/components/atoms/if/if";

import { OrderPaymentStatus } from "@/interfaces/order-payment-status.enum";
import { Order } from "@/interfaces/order.interface";
import { OrderStatus } from "@/interfaces/order-status.enum";

export function OrderHeader({
	order,
	handlePanel,
	panel,
	paymentMode,
}: {
	order: Order;
	handlePanel: () => void;
	panel: number;
	paymentMode: boolean;
}) {
	const navigate = useNavigate();

	const orderIsAnulated = order.status === OrderStatus.ANULATED;
	const orderIsPaid = order.paymentStatus === OrderPaymentStatus.PAID;
	return (
		<div className="d-flex justify-content-between align-items-center mt-2">
			<h2 className="m-0">{`Mesa ${order.tableNumber}`}</h2>
			<div className="d-flex gap-2">
				<Button onClick={() => navigate(-1)} mode="danger" size="small">
					Regresar
				</Button>
				<If condition={!orderIsAnulated && !orderIsPaid}>
					<Button
						disabled={paymentMode}
						mode="success"
						size="small"
						onClick={handlePanel}
					>
						{panel === 0 ? "Ver orden" : "Ver productos"}
					</Button>
				</If>
			</div>
		</div>
	);
}
