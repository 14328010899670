export const MONEY_MOVEMENT_TYPES = [
	{
		id: "ff055cb3-2642-4b0d-b6e7-f337d431f7e2",
		name: "Ingreso",
	},
	{
		id: "dd859e1f-b35d-4288-b2e6-f4f072944849",
		name: "Egreso",
	},
];
