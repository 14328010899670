import { currencyFormat } from "@/utils/currency-format";
import { roundToDecimals } from "@/utils/round-to-decimals";

import { OrderProductForm } from "@/interfaces/order-payment.interfaces";

export function OrderPaymentPreview(props: {
	paymentOrderProducts: OrderProductForm[];
}) {
	const { paymentOrderProducts } = props;

	return (
		<div className="card">
			<div className="card-header">
				<p className="mb-0">Productos Seleccionados</p>
			</div>
			<div className="card-body p-0">
				<div className="table-responsive">
					<table className="table table-sm">
						<thead>
							<tr>
								<th>Producto</th>
								<th>Cantidad</th>
								<th>Precio</th>
								<th>Descuento</th>
								<th>Subtotal</th>
							</tr>
						</thead>
						<tbody>
							{paymentOrderProducts
								.filter((p) => p.unitsToPay > 0)
								.map((p) => ({
									...p,
									details: p.details.filter((d) => d.unitsToPay > 0),
								}))
								.map((product) => (
									<tr key={product.id}>
										<td>{product.product.name}</td>
										<td>{product.unitsToPay}</td>
										<td>{currencyFormat(product.productPrice)}</td>
										<td>
											{product.details.reduce((acc, curr) => {
												const { discount } = curr;
												if (discount === 0) return acc;

												const { productPrice } = product;
												const { unitsToPay, amountToPay } = curr;
												const d = productPrice * unitsToPay - amountToPay;
												return roundToDecimals(d, 3) + acc;
											}, 0)}
										</td>
										<td className="text-end">
											{currencyFormat(product.amountToPay)}
										</td>
									</tr>
								))}
						</tbody>
						<tfoot>
							<tr>
								<td className="text-end" colSpan={4}>
									Total
								</td>
								<td className="text-end">
									{currencyFormat(
										paymentOrderProducts.reduce(
											(acc, curr) => curr.amountToPay + acc,
											0,
										),
									)}
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>
	);
}
