import { initializeApp } from "firebase/app";
import {
	NextOrObserver,
	User,
	getAuth,
	onAuthStateChanged,
	signInWithEmailAndPassword,
	signOut,
	connectAuthEmulator,
} from "firebase/auth";
import { isDevelopment } from "../utils/environment";

const firebaseConfig = {
	apiKey: import.meta.env.VITE_APP_apiKey || "la-ladrillera",
	authDomain: import.meta.env.VITE_APP_authDomain,
	projectId: import.meta.env.VITE_APP_projectId,
	storageBucket: import.meta.env.VITE_APP_storageBucket,
	messagingSenderId: import.meta.env.VITE_APP_messagingSenderId,
	appId: import.meta.env.VITE_APP_appId,
	measurementId: import.meta.env.VITE_APP_measurementId,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

if (isDevelopment) {
	const emulatorUrl = `http://${window.location.hostname}:9099`;
	connectAuthEmulator(auth, emulatorUrl, { disableWarnings: true });
}

const CUSTOM_EVENT_NAME = "newUserToken";
let isFetching = false;

export const authService = {
	getNewToken: async () => {
		const { currentUser } = auth;

		if (!currentUser) return null;

		if (isFetching) {
			return new Promise<string>((resolve) => {
				let unsubscribe: () => void = () => {};

				unsubscribe = authService.onNewToken((token) => {
					resolve(token);
					unsubscribe();
				});
			});
		}

		isFetching = true;

		const token = await currentUser.getIdToken(true);
		const event = new CustomEvent(CUSTOM_EVENT_NAME, { detail: token });
		window.dispatchEvent(event);

		isFetching = false;

		return token;
	},
	onNewToken: (callback: (token: string) => void) => {
		const handler = (event: Event) => {
			const token = (event as CustomEvent).detail;
			callback(token);
		};

		window.addEventListener(CUSTOM_EVENT_NAME, handler);

		return () => {
			window.removeEventListener(CUSTOM_EVENT_NAME, handler);
		};
	},
	signInWithEmailAndPassword: (email: string, password: string) => {
		return signInWithEmailAndPassword(auth, email, password);
	},
	onAuthStateChanged: (callback: NextOrObserver<User>) => {
		return onAuthStateChanged(auth, callback);
	},
	signOut: () => {
		return signOut(auth);
	},
};
