import { forwardRef, useId } from "react";

import { classNames } from "../../../utils/class-names";
import { If } from "../if/if";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	labelPlacement?: "top" | "bottom";
	labelClassName?: string;
}

export const Input = forwardRef(function Input(
	{
		type = "text",
		className = "",
		label,
		name,
		labelClassName,
		labelPlacement = "top",
		...rest
	}: InputProps,
	ref: React.Ref<HTMLInputElement>,
) {
	const { max, min, value } = rest;
	const id = useId();
	const inputClassName = ["radio", "checkbox"].includes(type)
		? undefined
		: "form-control";
	const localLabelClassName = ["radio", "checkbox"].includes(type)
		? undefined
		: "form-label";

	const isInvalid = () => {
		if (type !== "number") return false;
		if (typeof max === "undefined" && typeof min === "undefined") return false;
		if (typeof value === "undefined") return false;

		if (typeof min === "number" && Number(value) < min) return true;
		if (typeof max === "number" && Number(value) > max) return true;

		return false;
	};

	return (
		<>
			<If condition={!!label && labelPlacement === "top"}>
				<label
					htmlFor={id}
					className={classNames(localLabelClassName, labelClassName)}
				>
					{label}
				</label>
			</If>
			<input
				id={id}
				name={name}
				ref={ref}
				className={classNames(
					inputClassName,
					{
						"is-invalid": isInvalid(),
					},
					className,
				)}
				type={type}
				{...rest}
			/>
			<If condition={!!label && labelPlacement === "bottom"}>
				<label
					htmlFor={id}
					className={classNames(localLabelClassName, labelClassName)}
				>
					{label}
				</label>
			</If>
		</>
	);
});
