import { useState } from "react";

import { Button } from "@/components/atoms/button/button";
import { Input } from "@/components/atoms/input/input";
import { Modal } from "@/components/organisms/modal/modal";
import { TRANSACTION_METHODS } from "@/constants/transaction-method";
import { useCreateMoneyMovement } from "./use-create-money-movement";
import { formatDate } from "@/utils/date";
import { useAuth } from "@/store/use-auth";
import { CHECKOUTS } from "@/constants/checkout";
import { MONEY_MOVEMENT_TYPES } from "@/constants/money-movement";
import { If } from "@/components/atoms/if/if";
import { Loading } from "@/components/atoms/loading";

import { UserRole } from "@/interfaces/user-role.enum";
import { MoneyMovementForm as MoneyMovementFormInterface } from "@/interfaces/money-movement";
import { TransactionMethod } from "@/interfaces/transaction-methos.enum";

const CATEGORIES = [
	"COMPRA",
	"DEVOLUCIÓN",
	"DEVOLUCIÓN PROPINA",
	"PAGO SERVICIOS",
	"PAGO PERSONAL",
	"MOVIMIENTO INTERNO",
];
const MOVEMENT_AREAS = [
	{ name: "Restaurant", value: "RESTAURANT" },
	{ name: "Propina", value: "TIP" },
];
const movementForm: MoneyMovementFormInterface = {
	date: formatDate({ format: "en" }),
	typeId: undefined,
	checkoutId: undefined,
	category: "",
	concept: "",
	amount: 0,
	transactionMethod: TransactionMethod.CASH,
	area: "",
	invoiceType: "",
	invoiceIssuerRuc: "",
	invoiceNumber: "",
	observations: "",
};
const TODAY = formatDate({ format: "en" });

interface MoneyMovementFormProps {
	showModal: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
	onMoneyMovementCreated: () => void;
}

export function MoneyMovementForm(props: MoneyMovementFormProps) {
	const { showModal, setShowModal, onMoneyMovementCreated } = props;
	const { role } = useAuth();
	const [movement, setMovement] = useState<MoneyMovementFormInterface>({
		...movementForm,
		typeId: role === UserRole.CAJA ? MONEY_MOVEMENT_TYPES[1].id : undefined,
		checkoutId: role === UserRole.CAJA ? CHECKOUTS[0].id : undefined,
	});
	const { loading: l2, createMoneyMovement } = useCreateMoneyMovement();

	const handleChange = (
		e: React.ChangeEvent<
			HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
		>,
	) => {
		const { name, value } = e.target;

		setMovement((prev) => ({
			...prev,
			[name]: name === "amount" ? Number(value) : value,
		}));
	};

	return (
		<>
			<If condition={l2} children={<Loading />} />
			<Modal show={showModal} onClose={() => setShowModal(false)}>
				<div className="modal-header">
					<h5 className="modal-title">Registrar movimiento</h5>
					<button
						type="button"
						className="btn-close"
						onClick={() => setShowModal(false)}
					></button>
				</div>
				<div className="modal-body">
					<form className="row g-3" onSubmit={undefined}>
						<div className="col-md-6">
							<label htmlFor="type-id" className="form-label">
								Tipo
							</label>
							<select
								value={movement.typeId}
								name="typeId"
								onChange={handleChange}
								required
								id="type-id"
								className="form-select"
								disabled={UserRole.CAJA === role}
							>
								<option value="">Seleccionar...</option>
								{MONEY_MOVEMENT_TYPES.map(({ id, name }) => (
									<option key={id} value={id}>
										{name}
									</option>
								))}
							</select>
						</div>
						<div className="col-md-6">
							<Input
								type="date"
								name="date"
								max={TODAY}
								value={movement.date}
								onChange={handleChange}
								label="Fecha"
								disabled={UserRole.ADMIN !== role}
								required
							/>
						</div>
						<div className="col-md-6">
							<label htmlFor="checkout-id" className="form-label">
								Caja
							</label>
							<select
								value={movement.checkoutId}
								name="checkoutId"
								onChange={handleChange}
								id="checkout-id"
								className="form-select"
								disabled={UserRole.CAJA === role}
							>
								<option value="">Ninguna</option>
								{CHECKOUTS.map(({ id, name }) => (
									<option key={id} value={id}>
										{name}
									</option>
								))}
							</select>
						</div>
						<div className="col-md-6">
							<label htmlFor="category" className="form-label">
								Categoría
							</label>
							<select
								value={movement.category}
								name="category"
								onChange={(e) => {
									const isTip = e.target.value === "DEVOLUCIÓN PROPINA";
									setMovement({
										...movement,
										category: e.target.value,
										area: MOVEMENT_AREAS[isTip ? 1 : 0].value,
									});
								}}
								required
								id="category"
								className="form-select"
							>
								<option value="">Ninguna</option>
								{CATEGORIES.map((name) => (
									<option key={name} value={name}>
										{name}
									</option>
								))}
							</select>
						</div>
						<div className="col-md-6">
							<label htmlFor="area" className="form-label">
								Area
							</label>
							<select
								value={movement.area}
								name="area"
								onChange={handleChange}
								required
								id="area"
								className="form-select"
								disabled
							>
								<option value="">Ninguna</option>
								{MOVEMENT_AREAS.map(({ name, value }) => (
									<option key={value} value={value}>
										{name}
									</option>
								))}
							</select>
						</div>
						<div className="col-md-6">
							<label htmlFor="transaction-method" className="form-label">
								Método de pago
							</label>
							<select
								id="transaction-method"
								name="transactionMethod"
								className="form-select"
								value={movement.transactionMethod}
								onChange={handleChange}
								disabled={UserRole.CAJA === role}
							>
								{Object.entries(TransactionMethod).map(([key]) => (
									<option key={key} value={key}>
										{TRANSACTION_METHODS[key as TransactionMethod]}
									</option>
								))}
							</select>
						</div>
						<div className="col-12">
							<Input
								name="concept"
								value={movement.concept}
								onChange={handleChange}
								label="Concepto"
							/>
						</div>
						<div className="col-md-6">
							<Input
								name="amount"
								value={movement.amount}
								onChange={handleChange}
								label="Monto"
								type="number"
								min={0.5}
							/>
						</div>
						<div className="col-md-6">
							<label htmlFor="invoice-type" className="form-label">
								Documento
							</label>
							<select
								value={movement.invoiceType}
								name="invoiceType"
								onChange={handleChange}
								required
								id="invoice-type"
								className="form-select"
							>
								<option value="">Ninguno</option>
								<option value="INVOICE">Factura</option>
								<option value="RECEIPT">Boleta</option>
							</select>
						</div>
						<div className="col-md-6">
							<Input
								name="invoiceIssuerRuc"
								minLength={11}
								maxLength={11}
								value={movement.invoiceIssuerRuc}
								onChange={handleChange}
								label="RUC emisor"
							/>
						</div>
						<div className="col-md-6">
							<Input
								name="invoiceNumber"
								value={movement.invoiceNumber}
								onChange={handleChange}
								label="Número de documento"
							/>
						</div>
						<div className="col-md-12">
							<label htmlFor="observations" className="form-label">
								Observaciones
							</label>
							<textarea
								className="form-control"
								id="observations"
								name="observations"
								value={movement.observations}
								onChange={handleChange}
							/>
						</div>
					</form>
				</div>
				<div className="modal-footer">
					<Button mode="secondary" onClick={() => setShowModal(false)}>
						Cancelar
					</Button>
					<Button
						onClick={async () => {
							const response = await createMoneyMovement(movement);
							if (!response) return;
							onMoneyMovementCreated();
							setMovement({
								...movementForm,
								typeId:
									role === UserRole.CAJA
										? MONEY_MOVEMENT_TYPES[1].id
										: undefined,
								checkoutId:
									role === UserRole.CAJA ? CHECKOUTS[0].id : undefined,
							});
						}}
					>
						Guardar
					</Button>
				</div>
			</Modal>
		</>
	);
}
