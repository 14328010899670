import { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_ORDER_DAILY_REPORT } from "@/graphql";
import { GET_MONEY_MOVEMENT_SUMMARIES } from "@/graphql/queries";
import Layout from "./layout";
import { Loading } from "@/components/atoms/loading";
import { OrderReportSummary } from "@/components/organisms/order-report-summary/order-report-summary";
import { formatDate } from "@/utils/date";
import { currencyFormat } from "@/utils/currency-format";
import { If } from "@/components/atoms/if/if";
import { WithAuthenticationProps } from "@/hoc/withAuthentication";
import { TRANSACTION_METHODS } from "@/constants/transaction-method";
import { CHECKOUTS } from "@/constants/checkout";

import { OrderProductReport } from "@/interfaces/order-product-report.interface";
import { UserRole } from "@/interfaces/user-role.enum";
import {
	MoneyMovementSummary,
	MoneyMovementSummaryResponse,
} from "@/interfaces/reports";

interface OrderReportPageProps extends WithAuthenticationProps {}

const OrderReportPage: React.FC<OrderReportPageProps> = ({ role }) => {
	const [report, setReport] = useState<OrderProductReport[]>([]);
	const [moneyMovementSummary, setMoneyMovementSummary] = useState<
		MoneyMovementSummary[]
	>([]);
	const [date, setDate] = useState(formatDate({ format: "en" }));

	useQuery<{ getMoneyMovementSummaries: MoneyMovementSummaryResponse[] }>(
		GET_MONEY_MOVEMENT_SUMMARIES,
		{
			variables: {
				startDate: date,
				endDate: date,
				checkoutId: CHECKOUTS[0].id,
			},
			fetchPolicy: "no-cache",
			skip: ![UserRole.ADMIN, UserRole.CAJA].includes(role),
			onCompleted: (data) => {
				setMoneyMovementSummary(
					data.getMoneyMovementSummaries.map((el) => ({
						...el,
						date: new Date(el.date),
					})),
				);
			},
		},
	);

	const { loading: l1 } = useQuery(GET_ORDER_DAILY_REPORT, {
		variables: { date },
		skip: ![UserRole.ADMIN, UserRole.CAJA].includes(role),
		fetchPolicy: "no-cache",
		onCompleted: (data: { orderDailyReport: OrderProductReport[] }) => {
			setReport(data.orderDailyReport);
		},
	});

	return (
		<Layout>
			<If condition={l1} children={<Loading />} />
			<If condition={![UserRole.ADMIN, UserRole.CAJA].includes(role)}>
				<div className="alert alert-danger mt-2">
					<p>
						No tienes permisos para acceder a esta página{" "}
						<Link to="/">Regresar</Link>
					</p>
				</div>
			</If>
			<If condition={[UserRole.ADMIN, UserRole.CAJA].includes(role)}>
				<div className="row mt-2">
					<div className="mb-3 row">
						<label
							htmlFor="inputDate"
							className="col-2 col-md-1 col-form-label"
						>
							Fecha
						</label>
						<div className="col-7 col-md-4">
							<input
								id="inputDate"
								className="form-control"
								type="date"
								value={date}
								onChange={(e) => setDate(e.target.value)}
							/>
						</div>
					</div>
					<div className="col-12 col-md-4">
						<h3>Pagos</h3>
						<table className="table table-sm">
							<thead>
								<tr>
									<th>Forma de pago</th>
									<th className="text-end">Monto</th>
								</tr>
							</thead>
							<tbody>
								{moneyMovementSummary
									.filter((el) => el.type.name === "Ingreso")
									.filter((el) => el.area === "RESTAURANT")
									.map((el, id) => (
										<tr key={id}>
											<td>{TRANSACTION_METHODS[el.transactionMethod]}</td>
											<td className="text-end">{currencyFormat(el.amount)}</td>
										</tr>
									))}
							</tbody>
							<tfoot>
								<tr>
									<td className="text-end">Total</td>
									<td className="fw-bold text-end">
										{currencyFormat(
											moneyMovementSummary
												.filter((el) => el.type.name === "Ingreso")
												.filter((el) => el.area === "RESTAURANT")
												.reduce((a, b) => a + b.amount, 0) || 0,
										)}
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
					<div className="col-12 col-md-4">
						<h3>Propinas</h3>
						<table className="table table-sm">
							<thead>
								<tr>
									<th>Forma de pago</th>
									<th className="text-end">Monto</th>
								</tr>
							</thead>
							<tbody>
								{moneyMovementSummary
									.filter((el) => el.type.name === "Ingreso")
									.filter((el) => el.area === "TIP")
									.map((el, id) => (
										<tr key={id}>
											<td>{TRANSACTION_METHODS[el.transactionMethod]}</td>
											<td className="text-end">{currencyFormat(el.amount)}</td>
										</tr>
									))}
							</tbody>
							<tfoot>
								<tr>
									<td className="text-end">Total</td>
									<td className="fw-bold text-end">
										{currencyFormat(
											moneyMovementSummary
												.filter((el) => el.type.name === "Ingreso")
												.filter((el) => el.area === "TIP")
												.reduce((a, b) => a + b.amount, 0) || 0,
										)}
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
					<div className="col-12 col-md-4">
						<h3>Egresos</h3>
						<table className="table table-sm">
							<thead>
								<tr>
									<th>Concepto</th>
									<th>Forma de pago</th>
									<th className="text-end">Monto</th>
								</tr>
							</thead>
							<tbody>
								{moneyMovementSummary
									.filter((el) => el.type.name === "Egreso")
									.map((el, id) => (
										<tr key={id}>
											<td>{el.concept}</td>
											<td>{TRANSACTION_METHODS[el.transactionMethod]}</td>
											<td className="text-end">{currencyFormat(el.amount)}</td>
										</tr>
									))}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan={2} className="text-end">
										Total
									</td>
									<td className="fw-bold text-end">
										{currencyFormat(
											moneyMovementSummary
												.filter((el) => el.type.name === "Egreso")
												.reduce((a, b) => a + b.amount, 0) || 0,
										)}
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
					<If condition={!!moneyMovementSummary}>
						<OrderReportSummary list={moneyMovementSummary} />
					</If>
					<div className="col-12 col-md-4">
						<h3>Productos</h3>
						<table className="table table-sm">
							<thead>
								<tr>
									<th>Producto</th>
									<th>Precio</th>
									<th>Cant</th>
									<th className="text-end">Subtotal</th>
								</tr>
							</thead>
							<tbody>
								{report.map((el, id) => (
									<tr key={id}>
										<td>{el.productName}</td>
										<td>{currencyFormat(el.productPrice)}</td>
										<td>{el.quantity}</td>
										<td className="text-end">{currencyFormat(el.subTotal)}</td>
									</tr>
								))}
							</tbody>
							<tfoot>
								<tr>
									<td colSpan={3} className="text-end">
										Total
									</td>
									<td className="fw-bold text-end">
										{currencyFormat(report.reduce((a, b) => a + b.subTotal, 0))}
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</If>
		</Layout>
	);
};

export default OrderReportPage;
