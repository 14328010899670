export function divideAmount(amount: number, peopleCount: number) {
	const baseAmount = Math.floor((amount / peopleCount) * 10) / 10;
	let remaining = Math.round((amount - baseAmount * peopleCount) * 10);

	const amounts = Array(peopleCount).fill(baseAmount);

	for (let i = 0; i < peopleCount && remaining > 0; i++) {
		amounts[i] += 0.1;
		remaining -= 1;
	}

	return amounts.map((amount) => Number(amount.toFixed(1)));
}
