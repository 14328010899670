import {
	OrderProductDetailForm,
	OrderProductForm,
} from "@/interfaces/order-payment.interfaces";

export function getPaymentOrderProductDetailStatusBadge(
	detail: OrderProductDetailForm,
	orderProduct: OrderProductForm,
) {
	const { paidAmount, discount } = detail;
	const { productPrice, paidUnits } = orderProduct;
	let statusClass = "--bs-warning";

	if (paidAmount === 0 && paidUnits === 0) {
		statusClass = "--bs-secondary";
	} else if (productPrice - discount - paidAmount === 0) {
		statusClass = "--bs-success";
	}

	return (
		<span style={{ color: `var(${statusClass})` }}>
			<svg
				fill="currentColor"
				width={16}
				viewBox="0 0 20 20"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="10" cy="10" r="10" />
			</svg>
		</span>
	);
}

export function getPaymentOrderProductStatusBadge(product: OrderProductForm) {
	const { productSubTotal, paidAmount, paidUnits } = product;
	let statusClass = "bg-warning";
	let statusText = "Parcial";

	if (paidAmount === 0 && paidUnits === 0) {
		statusClass = "bg-secondary";
		statusText = "Pendiente";
	} else if (productSubTotal - paidAmount === 0) {
		statusClass = "bg-success";
		statusText = "Completo";
	}

	return (
		<span className={`ms-2 badge rounded-pill ${statusClass}`}>
			{statusText}
		</span>
	);
}
