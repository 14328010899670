import { currencyFormat } from "@/utils/currency-format";
import { Checkbox } from "@/components/organisms/checkbox";
import { Input } from "@/components/atoms/input/input";
import { If } from "@/components/atoms/if/if";

import { InvoiceDetail } from "@/interfaces/invoice-detail";
import { truncateToSingleDecimal } from "@/utils/truncate-to-single-decimal";

interface InvoiceDetailsProps {
	isRegistered: boolean;
	invoiceDetails: InvoiceDetail[];
	total: number;
	editEnabled: boolean;
	updateInvoiceDetails: (detail: InvoiceDetail) => void;
	changeEditEnabled: (editEnabled: boolean) => void;
}

export function InvoiceDetails(props: InvoiceDetailsProps) {
	const { isRegistered, invoiceDetails, total, editEnabled } = props;
	const { changeEditEnabled, updateInvoiceDetails } = props;

	const handleUnitsChange = (detail: InvoiceDetail, units: number) => {
		units = isNaN(units) ? 0 : units;
		units = truncateToSingleDecimal(units);
		const newDetail = { ...detail, units, total: units * detail.unitPrice };

		updateInvoiceDetails(newDetail);
	};

	const handleUnitPriceChange = (detail: InvoiceDetail, unitPrice: number) => {
		unitPrice = isNaN(unitPrice) ? 0 : unitPrice;
		unitPrice = truncateToSingleDecimal(unitPrice);
		const newDetail = { ...detail, unitPrice, total: detail.units * unitPrice };

		updateInvoiceDetails(newDetail);
	};

	const newTotal = invoiceDetails.reduce(
		(acc, detail) => acc + detail.total,
		0,
	);
	const isDifferent = newTotal !== total;
	return (
		<section>
			<div className="d-flex justify-content-between">
				<h3>Detalles</h3>
				<If condition={!isRegistered}>
					<div className="form-check">
						<Checkbox
							checked={editEnabled}
							onChange={({ target }) => changeEditEnabled(target.checked)}
							label="Editar"
						/>
					</div>
				</If>
			</div>
			<div className="table-responsive">
				<table className="table table-sm">
					<thead>
						<tr>
							<th>Código</th>
							<th>Producto</th>
							<th>Cant.</th>
							<th>Precio</th>
							<th>Desc.</th>
							<th className="text-end">Subtotal</th>
						</tr>
					</thead>
					<tbody>
						{invoiceDetails.map((product) => (
							<tr key={product.code}>
								<td>{product.code}</td>
								<td>{product.name}</td>
								<td>
									<If condition={editEnabled}>
										<Input
											style={{ width: "100px" }}
											type="number"
											value={product.units.toString()}
											min={0.5}
											step={0.5}
											onChange={(e) =>
												handleUnitsChange(product, e.target.valueAsNumber)
											}
										/>
									</If>
									<If condition={!editEnabled}>{product.units}</If>
								</td>
								<td>
									<If condition={editEnabled}>
										<Input
											style={{ width: "100px" }}
											type="number"
											value={product.unitPrice.toString()}
											min={0.1}
											step={0.1}
											onChange={(e) =>
												handleUnitPriceChange(product, e.target.valueAsNumber)
											}
										/>
									</If>
									<If condition={!editEnabled}>
										{currencyFormat(product.unitPrice)}
									</If>
								</td>
								<td>{product.discount}</td>
								<td style={{ width: "120px" }} className="text-end">
									{currencyFormat(product.total)}
								</td>
							</tr>
						))}
					</tbody>
					<tfoot>
						<tr>
							<td className="text-end" colSpan={6}>
								<If condition={total < newTotal}>
									<span className="text-danger me-4">(Monto excedido)</span>
								</If>
								<span className="me-4"> Total</span>
								<If condition={isDifferent}>
									<span className="me-2 text-danger text-decoration-line-through">
										{currencyFormat(total)}
									</span>
									<span>{currencyFormat(newTotal)}</span>
								</If>
								<If condition={!isDifferent}>{currencyFormat(total)}</If>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</section>
	);
}
