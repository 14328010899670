import {
	OrderPayment,
	OrderProductForm,
	OrderProductDetailForm,
} from "@/interfaces/order-payment.interfaces";
import { CheckboxStates } from "@/interfaces/checkbox-statuses.enum";
import { OrderOrderPaymentStatus } from "@/interfaces/order-order-payment-status";

export function joinPaymentToPaymentOrder(
	OrderProducts: OrderProductForm[],
	payments: OrderPayment[],
) {
	if (payments.length === 0) return OrderProducts;

	const paymentDetails = payments
		.map((payment) =>
			payment.status === OrderOrderPaymentStatus.CANCELED
				? []
				: payment.orderProductDetails,
		)
		.flat();

	const products = OrderProducts.map((product): OrderProductForm => {
		const details = product.details.map((d) => {
			const details = paymentDetails.filter((detail) => detail.id === d.id);

			if (details.length > 0) {
				const amount = details.reduce((acc, curr) => curr.amount + acc, 0);
				const units = details.reduce((acc, curr) => curr.units + acc, 0);

				const newDetail: OrderProductDetailForm = {
					...d,
					paidUnits: units + d.paidUnits,
					paidAmount: amount + d.paidAmount,
					unitsToPay: 0,
					amountToPay: 0,
				};

				return newDetail;
			}
			return d;
		});

		return {
			...product,
			checkboxState: CheckboxStates.Empty,
			paidUnits: details.reduce((acc, curr) => curr.paidUnits + acc, 0),
			paidAmount: details.reduce((acc, curr) => curr.paidAmount + acc, 0),
			unitsToPay: 0,
			amountToPay: 0,
			details,
		};
	});

	return products;
}
