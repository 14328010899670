import { InvoiceResponse, Invoice } from "@/interfaces/invoice";

export function invoiceResponseToInvoice(
	invoiceResponse: InvoiceResponse,
): Invoice {
	const { cancellation } = invoiceResponse;

	return {
		...invoiceResponse,
		date: new Date(invoiceResponse.date),
		orderPayments: invoiceResponse.orderPayments.map((op) => ({
			...op,
			date: new Date(op.date),
		})),
		cancellation: cancellation
			? {
					...cancellation,
					date: new Date(cancellation.date),
				}
			: null,
	};
}
