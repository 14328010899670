import { useState, useCallback } from "react";

import Collapse from "./collapse";
import OrderProduct from "./order-product";
import { ExpandedArrow } from "../atoms/expanded-arrow/expanded-arrow";
import { If } from "@/components/atoms/if/if";
import { Checkbox } from "./checkbox";
import { OrderReducerAction, OrderReducerType } from "@/utils/order-reducer";

import { OrderStatus } from "@/interfaces/order-status.enum";
import { OrderPaymentStatus } from "@/interfaces/order-payment-status.enum";
import {
	OrderForm,
	OrderProductDetailForm,
	OrderProductForm,
} from "@/interfaces/order-payment.interfaces";
import { CheckboxStates } from "@/interfaces/checkbox-statuses.enum";

const productDetails: OrderProductDetailForm = {
	id: null,
	discount: 0,
	discountRate: 0.0,
	delivered: false,
	options: [],
	registrationDate: new Date(),
	amountToPay: 0,
	paidAmount: 0,
	paidUnits: 0,
	unitsToPay: 0,
};

interface OrderProductsProps {
	order: OrderForm;
	paymentMode: boolean;
	reducer: React.Dispatch<OrderReducerAction>;
}

const OrderProducts: React.FC<OrderProductsProps> = (props) => {
	const { order, reducer, paymentMode } = props;
	const [isOpen, setIsOpen] = useState(false);

	const handleAdd = useCallback(
		(orderDetail: OrderProductForm) => {
			let orderProduct = { ...orderDetail };
			orderProduct = {
				...orderProduct,
				quantity: orderProduct.quantity + 1,
				productSubTotal:
					orderProduct.productSubTotal + orderProduct.productPrice,
				details: [
					...orderProduct.details,
					{ ...productDetails, registrationDate: new Date() },
				],
			};
			reducer({
				type: OrderReducerType.ADD,
				payload: { products: [orderProduct] },
			});
		},
		[reducer],
	);

	const handleRemove = useCallback(
		(orderDetail: OrderProductForm, index: number) => {
			const od = { ...orderDetail };
			od.quantity -= 1;
			const res = od.details.splice(index, 1);
			od.productSubTotal =
				od.productSubTotal - (od.productPrice - res[0].discount);

			reducer({ type: OrderReducerType.REMOVE, payload: { products: [od] } });
		},
		[reducer],
	);

	const handleUpdateDiscount = useCallback(
		(
			orderDetail: OrderProductForm,
			index: number,
			type: "discount" | "discountRate",
			value: string,
		) => {
			const od = { ...orderDetail };
			const parsedValue = parseFloat(value || "0");

			if (type === "discountRate") {
				if (parsedValue >= 100) {
					od.details[index].discountRate = 100;
					od.details[index].discount = od.productPrice;
				} else {
					od.details[index].discountRate = parsedValue;
					const discount = (od.details[index][type] * od.productPrice) / 100;
					od.details[index].discount = parseFloat(discount.toFixed(1));
				}
			} else {
				if (parsedValue >= od.productPrice) {
					od.details[index].discount = od.productPrice;
					od.details[index].discountRate = 100;
				} else {
					od.details[index].discount = parsedValue;
					const discountRate =
						(od.details[index][type] / od.productPrice) * 100;
					od.details[index].discountRate = parseInt(discountRate.toFixed(0));
				}
			}

			const sumProducts = (a: number, b: OrderProductDetailForm) =>
				a + od.productPrice - b.discount;
			od.productSubTotal = od.details.reduce(sumProducts, 0);

			reducer({
				type: OrderReducerType.UPDATE_DISCOUNT,
				payload: { products: [od] },
			});
		},
		[reducer],
	);

	const handleSelectAllToPay = useCallback(
		(checked: boolean) => {
			reducer({
				type: OrderReducerType.SELECT_ALL_FOR_PAYMENT,
				payload: {
					checkboxState: checked
						? CheckboxStates.Checked
						: CheckboxStates.Empty,
				},
			});
		},
		[reducer],
	);

	const handleSelectOneToPay = useCallback(
		(orderDetail: OrderProductForm, checked: boolean) => {
			const od = { ...orderDetail };
			od.checkboxState = checked
				? CheckboxStates.Checked
				: CheckboxStates.Empty;

			reducer({
				type: OrderReducerType.SELECT_ONE_FOR_PAYMENT,
				payload: { products: [od] },
			});
		},
		[reducer],
	);

	const onOrderProductChange = useCallback(
		(orderProduct: OrderProductForm) => {
			reducer({
				type: OrderReducerType.PRODUCT_CHANGE_FOR_PAYMENT,
				payload: { products: [orderProduct] },
			});
		},
		[reducer],
	);

	const orderIsAnulated = order.status === OrderStatus.ANULATED;
	const isTotallyPaid = order.paymentStatus === OrderPaymentStatus.PAID;
	const isModifiable = !(orderIsAnulated || isTotallyPaid);
	return (
		<>
			<div className="card mt-2">
				<div className="card-header p-1 d-flex justify-content-between">
					<p className="mb-0">Productos</p>
					<div className="form-check">
						<If condition={paymentMode}>
							<Checkbox
								disabled={order.paymentStatus === OrderPaymentStatus.PAID}
								checked={order.checkboxState === CheckboxStates.Checked}
								undeterminated={
									order.checkboxState === CheckboxStates.Indeterminate
								}
								onChange={(e) => handleSelectAllToPay(e.target.checked)}
								label="Seleccionar todo"
							/>
						</If>
					</div>
				</div>
				<ul className="list-group list-group-flush">
					<If condition={order.products.length === 0}>
						<li className="list-group-item text-center">
							No hay productos seleccionados
						</li>
					</If>
					{order.products.map((op) => (
						<OrderProduct
							key={op.product.id}
							order={order}
							add={handleAdd}
							remove={handleRemove}
							update={handleUpdateDiscount}
							handleSelectOneToPay={handleSelectOneToPay}
							onOrderProductChange={onOrderProductChange}
							orderProduct={op}
							isModifiable={isModifiable}
							paymentMode={paymentMode}
						/>
					))}
				</ul>
			</div>
			<If condition={!paymentMode}>
				<div className="card mt-2">
					<div className="card-header p-1 d-flex justify-content-between">
						Datos extras
						<ExpandedArrow
							expanded={isOpen}
							setIsExpanded={setIsOpen}
							ariaLabel="Área de campos extras"
						/>
					</div>
					<ul className="list-group list-group-flush">
						<li className="list-group-item p-0">
							<Collapse classNames="row px-1" isOpen={isOpen}>
								<div className="col-12 col-md-6">
									<label htmlFor="nombre_cliente" className="form-label mb-0">
										Nombre cliente
									</label>
									<input
										id="nombre_cliente"
										disabled={!isModifiable}
										type="text"
										onChange={({ target }) =>
											reducer({
												type: OrderReducerType.CLIENT_NAME,
												payload: { clientName: target.value },
											})
										}
										value={order.clientName || ""}
										className="form-control mb-1"
										placeholder="Nombre de cliente"
									/>
								</div>
								<div className="col-12 col-md-6">
									<label htmlFor="observations" className="form-label mb-0">
										Observaciones
									</label>
									<input
										id="observations"
										disabled={!isModifiable}
										type="text"
										onChange={({ target }) =>
											reducer({
												type: OrderReducerType.OBSERVATIONS,
												payload: { observations: target.value },
											})
										}
										value={order.observations || ""}
										className="form-control mb-1"
										placeholder="Observaciones"
									/>
								</div>
							</Collapse>
						</li>
					</ul>
				</div>
			</If>
		</>
	);
};

export default OrderProducts;
