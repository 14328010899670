import { DocumentType } from "@/interfaces/document-type.enum";

export const DOCUMENT_TYPES = [
	{ value: DocumentType.RUC, label: "RUC" },
	{ value: DocumentType.DNI, label: "DNI" },
	{ value: DocumentType.CE, label: "CARNE DE EXTRANJERÍA" },
	{ value: DocumentType.PASS, label: "PASAPORTE" },
	{ value: DocumentType.CD, label: "CÉDULA DIPLOMÁTICA" },
	{ value: DocumentType.SD, label: "SIN DOCUMENTO" },
];
