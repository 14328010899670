import { FC, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import HomePage from "./pages/home";
import Login from "./pages/login";
import OrdersPage from "./pages/orders";
import OrderPage from "./pages/order";
import OrderReportPage from "./pages/order-report";
import { authService } from "./services/firebase";
import withAuthentication from "./hoc/withAuthentication";
import { useAuthMutations } from "./store/use-auth-mutations";
import { InvoicePage } from "./pages/invoice/invoice";
import { InvoicesPage } from "./pages/invoices/invoices";
import { MoneyMovementsPage } from "./pages/movements/money-movements";

const Home = withAuthentication(HomePage);
const Orders = withAuthentication(OrdersPage);
const Order = withAuthentication(OrderPage);
const OrderReport = withAuthentication(OrderReportPage);
const Invoice = withAuthentication(InvoicePage);
const Invoices = withAuthentication(InvoicesPage);
const Movements = withAuthentication(MoneyMovementsPage);

const App: FC = () => {
	const { updateToken } = useAuthMutations();

	useEffect(() => {
		const id = window.setInterval(() => {
			authService.getNewToken();
		}, 3000000);
		const unsubscribe = authService.onNewToken(updateToken);

		return () => {
			unsubscribe();
			clearInterval(id);
		};
	}, [updateToken]);

	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/login" element={<Login />} />
			<Route path="/order/:id?" element={<Order />} />
			<Route path="/orders" element={<Orders />} />
			<Route path="/order-report" element={<OrderReport />} />
			<Route path="/invoice/:id?" element={<Invoice />} />
			<Route path="/invoices" element={<Invoices />} />
			<Route path="/movements" element={<Movements />} />
		</Routes>
	);
};

export default App;
