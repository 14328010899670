import { createContext } from "react";
import { ConfirmDialog } from ".";

export type ConfirmDialogProps = Partial<
	Omit<
		React.ComponentProps<typeof ConfirmDialog>,
		"open" | "onCancel" | "onConfirm"
	>
>;

interface ConfirmDialogAction {
	confirmRef?: React.MutableRefObject<
		(props?: ConfirmDialogProps) => Promise<boolean>
	>;
}
const defaultValue: ConfirmDialogAction = {
	confirmRef: { current: () => Promise.resolve(true) },
};

export const ConfirmDialogContext =
	createContext<ConfirmDialogAction>(defaultValue);
