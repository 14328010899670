import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";

import { CANCEL_ORDER_PAYMENT } from "../graphql/mutations";
import { useConfirmDialog } from "./use-confirm-dialog";

interface CancelOrderPaymentResponse {
	cancelOrderPayment: boolean;
}

export function useCancelOrderPayment() {
	const [mutation, { loading }] =
		useMutation<CancelOrderPaymentResponse>(CANCEL_ORDER_PAYMENT);
	const { confirm } = useConfirmDialog();

	const cancelOrderPayment = async (orderPaymentId: string) => {
		const resp = await confirm({
			content: "Se procederá a anular el pago",
		});

		if (!resp) return false;
		try {
			const { data } = await mutation({
				variables: {
					orderPaymentId,
				},
			});

			if (!data?.cancelOrderPayment)
				throw new Error("No se pudo anular el pago");

			await confirm({
				title: "Ok",
				content: "Pago anulado correctamente",
				type: "success",
				showCancelButton: false,
			});

			return data.cancelOrderPayment;
		} catch (error: unknown) {
			await confirm({
				title: "Ups, ocurrió un error",
				content: (error as Error).message,
				type: "error",
				showCancelButton: false,
			});

			Sentry.captureException(error);
			return false;
		}
	};

	return { loading, cancelOrderPayment };
}
