import { useContext } from "react";
import { AuthContext } from "./auth";

export const useAuth = () => {
	const { auth, id, name, role, token } = useContext(AuthContext);

	return {
		auth,
		id,
		name,
		role,
		token,
	};
};
