import { useState } from "react";
import { Link, createSearchParams } from "react-router-dom";

import { currencyFormat } from "@/utils/currency-format";
import { dateToTime } from "@/utils/date";
import { If } from "@/components/atoms/if/if";
import { Button } from "@/components/atoms/button/button";
import { useCancelOrderPayment } from "@/hooks/use-cancel-order-payment";
import { joinPaymentToPaymentOrder } from "@/pages/payment/join-payment-to-order";
import { Loading } from "@/components/atoms/loading";
import { OrderReducerAction, OrderReducerType } from "@/utils/order-reducer";
import { TRANSACTION_METHODS } from "@/constants/transaction-method";
import { ORDER_PAYMENT_IDS } from "@/constants/search-params";
import { Checkbox } from "./checkbox";

import { OrderOrderPaymentStatus } from "@/interfaces/order-order-payment-status";
import { OrderForm, OrderPayment } from "@/interfaces/order-payment.interfaces";
import { OrderPaymentStatus } from "@/interfaces/order-payment-status.enum";

interface OrderPaymentsProps {
	order: OrderForm;
	orderReducer: React.Dispatch<OrderReducerAction>;
}

export function OrderPayments({ order, orderReducer }: OrderPaymentsProps) {
	const { cancelOrderPayment, loading } = useCancelOrderPayment();
	const [enableMultipleSelection, setEnableMultipleSelection] = useState(false);
	const [orderPaymentIds, setOrderPaymentIds] = useState<{
		[key: string]: boolean;
	}>({});

	const handleCancelOrderPayment = async (payment: OrderPayment) => {
		const res = await cancelOrderPayment(payment.id);

		if (!res) return;

		const filtered = order.payments.map((p) =>
			p.id === payment.id
				? { ...p, status: OrderOrderPaymentStatus.CANCELED }
				: p,
		);
		const prod = order.products.map((p) => ({
			...p,
			details: p.details.map((d) => ({
				...d,
				amountToPay: 0,
				unitsToPay: 0,
				paidAmount: 0,
				paidUnits: 0,
			})),
		}));
		const response = joinPaymentToPaymentOrder(prod, filtered);
		const forPayIsZero = order.total - payment.amount === 0;
		orderReducer({
			type: OrderReducerType.SET,
			payload: {
				...order,
				paymentStatus: forPayIsZero
					? OrderPaymentStatus.PENDING
					: OrderPaymentStatus.PARTIAL,
				products: response,
				payments: filtered,
			},
		});
	};

	return (
		<>
			<If condition={loading} children={<Loading />} />
			<div className="card mt-2">
				<div className="card-header p-1 d-flex justify-content-between">
					<p className="mb-0">Pagos</p>
					<If
						condition={
							order.payments.filter(
								(op) =>
									!op.invoiceId &&
									op.status !== OrderOrderPaymentStatus.CANCELED,
							).length > 1
						}
					>
						<div className="form-check">
							<Checkbox
								label="Seleccionar varios pagos"
								checked={enableMultipleSelection}
								onChange={() => {
									setEnableMultipleSelection(!enableMultipleSelection);
									setOrderPaymentIds({});
								}}
							/>
						</div>
					</If>
				</div>
				<div className="card-body p-1">
					{order.payments.map((payment) => (
						<div key={payment.id} className="row mt-1">
							<div className="col-6 col-md-2">
								<p className="mb-0">{dateToTime(payment.date)}</p>
							</div>
							<div className="col-6 col-md-2">
								<p className="mb-0">{currencyFormat(payment.amount)}</p>
							</div>
							<div className="col-6 col-md-1">
								<p className="mb-0">{currencyFormat(payment.tipAmount)}</p>
							</div>
							<div className="col-6 col-md-2">
								<p className="mb-0">
									{TRANSACTION_METHODS[payment.transactionMethod]}
								</p>
							</div>
							<div className="col-6 col-md-3">
								<p className="mb-0">
									{payment.status === OrderOrderPaymentStatus.PAID
										? "REGISTRADO"
										: "ANULADO"}
								</p>
							</div>
							<div className="col-6 col-md-2 ms-auto text-end text-md-start pe-md-0 pe-4">
								<If
									condition={
										payment.status === OrderOrderPaymentStatus.PAID &&
										!enableMultipleSelection
									}
								>
									<Button
										mode="light"
										disabled={!payment.canBeCanceled}
										size="small"
										onClick={() => handleCancelOrderPayment(payment)}
									>
										Anular
									</Button>
								</If>
								<If
									condition={
										!enableMultipleSelection &&
										payment.status !== OrderOrderPaymentStatus.CANCELED
									}
								>
									<Link
										to={{
											pathname: !payment.invoiceId
												? "/invoice"
												: `/invoice/${payment.invoiceId}`,
											search: !payment.invoiceId
												? `${ORDER_PAYMENT_IDS}=${payment.id}`
												: "",
										}}
										className="btn btn-secondary btn-sm ms-2"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="16"
											height="16"
											fill="currentColor"
											className="me-1 mb-1"
											viewBox="0 0 16 16"
										>
											<path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27m.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0z" />
											<path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5" />
										</svg>
										{!payment.invoiceId ? "Generar" : "Ver"}
									</Link>
								</If>
								<If
									condition={
										enableMultipleSelection &&
										payment.status === OrderOrderPaymentStatus.PAID &&
										!payment.invoiceId
									}
								>
									<div className="form-check form-check-inline">
										<Checkbox
											name={`payment-${payment.id}`}
											label="Seleccionar"
											checked={orderPaymentIds[payment.id]}
											onChange={(e) =>
												setOrderPaymentIds({
													...orderPaymentIds,
													[payment.id]: e.target.checked,
												})
											}
										/>
									</div>
								</If>
							</div>
						</div>
					))}
				</div>
				<If
					condition={
						enableMultipleSelection &&
						Object.values(orderPaymentIds).filter((x) => x).length > 1
					}
				>
					<div className="d-flex justify-content-end">
						<Link
							className="btn btn-secondary btn-sm me-2 mb-2"
							to={{
								pathname: "/invoice",
								search: createSearchParams({
									[ORDER_PAYMENT_IDS]: Object.entries(orderPaymentIds)
										.filter((x) => x[1])
										.map((x) => x[0]),
								}).toString(),
							}}
						>
							Generar
						</Link>
					</div>
				</If>
			</div>
		</>
	);
}
