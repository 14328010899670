import { Worker, Viewer } from "@react-pdf-viewer/core";
import { RenderPrintProps, printPlugin } from "@react-pdf-viewer/print";
import { getFilePlugin } from "@react-pdf-viewer/get-file";

import { Button } from "@/components/atoms/button/button";
import { Modal } from "@/components/organisms/modal/modal";
import { useAuth } from "@/store/use-auth";

import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";

interface PdfViewerProps {
	fileUrl: string;
	showModal: boolean;
	fileName?: string;
	setShowModal: (showModal: boolean) => void;
}

export function PdfViewer(props: PdfViewerProps) {
	const { fileUrl, showModal, fileName, setShowModal } = props;
	const { token } = useAuth();
	const printPluginInstance = printPlugin();
	const { Print } = printPluginInstance;

	const getFilePluginInstance = getFilePlugin({
		fileNameGenerator: (file) => `${fileName ?? file.name}.pdf`,
	});
	const { Download } = getFilePluginInstance;

	return (
		<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.10.111/build/pdf.worker.min.js">
			<Modal show={showModal}>
				<div className="modal-body">
					<div
						style={{
							flex: 1,
							overflow: "hidden",
							height: "550px",
							width: "100%",
						}}
					>
						<Viewer
							httpHeaders={{
								Authorization: `Bearer ${token}`,
							}}
							fileUrl={fileUrl}
							plugins={[printPluginInstance, getFilePluginInstance]}
						/>
					</div>
				</div>
				<div className="modal-footer">
					<Button mode="secondary" onClick={() => setShowModal(false)}>
						Cerrar
					</Button>
					<Download>
						{(props) => (
							<Button className="ms-1" onClick={props.onClick}>
								Descargar
							</Button>
						)}
					</Download>
					<Print>
						{(props: RenderPrintProps) => (
							<Button className="ms-1" mode="success" onClick={props.onClick}>
								Imprimir
							</Button>
						)}
					</Print>
				</div>
			</Modal>
		</Worker>
	);
}
