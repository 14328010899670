import { FC } from "react";
import { Button } from "../atoms/button/button";

interface ButtonGroupProps {
	list: { id: string; name: string }[];
	activeIndex: number;
	setIndex: (index: number) => void;
}

const ButtonGroup: FC<ButtonGroupProps> = (props) => {
	const { list, activeIndex, setIndex } = props;

	return (
		<div className="btn-group col-12 overflow-auto my-2">
			{list.map((c, i) => (
				<Button
					key={c.id}
					mode="secondary"
					active={i === activeIndex}
					onClick={() => setIndex(i)}
				>
					{c.name}
				</Button>
			))}
		</div>
	);
};

export default ButtonGroup;
