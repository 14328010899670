import {
	useReducer,
	createContext,
	Dispatch,
	FC,
	PropsWithChildren,
} from "react";
import { AuthReducerType } from "../interfaces/auth-reducer-type.enum";
import { AuthState } from "../interfaces/auth-state.interface";
import { AuthReducerAction } from "../interfaces/auth-reducer-action.interface";
import { UserRole } from "@/interfaces/user-role.enum";

const initialState: AuthState = {
	auth: false,
	id: "",
	name: "",
	role: UserRole.WITHOUT_ROLE,
	token: "",
};

export const AuthContext = createContext<AuthState>(initialState);
export const AuthDispatchContext = createContext<Dispatch<AuthReducerAction>>(
	() => ({}) as AuthReducerAction,
);

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
	const [state, dispatch] = useReducer(authReducers, initialState);

	return (
		<AuthContext.Provider value={state}>
			<AuthDispatchContext.Provider value={dispatch}>
				{children}
			</AuthDispatchContext.Provider>
		</AuthContext.Provider>
	);
};

function authReducers(
	current: AuthState,
	action: AuthReducerAction,
): AuthState {
	const { type, item } = action;

	switch (type) {
		case AuthReducerType.LOGIN: {
			return {
				auth: true,
				id: item.id,
				name: item.name,
				role: item.role,
				token: item.token,
			};
		}

		case AuthReducerType.UPDATE_TOKEN: {
			return {
				...current,
				token: item,
			};
		}

		case AuthReducerType.LOGOUT: {
			return initialState;
		}

		default: {
			throw new Error(`Unhandled action type: ${type}`);
		}
	}
}

export default AuthProvider;
