import { useState } from "react";

import { Button } from "@/components/atoms/button/button";
import { Modal } from "@/components/organisms/modal/modal";
import { If } from "@/components/atoms/if/if";

interface InvoiceSendWhatsappProps {
	whatsappServerUrl: string;
	invoiceType: string;
	showModal: boolean;
	documents: {
		xml: string;
		cdr: string | null;
		pdf: string | null;
	};
	closeModal: () => void;
}

export function InvoiceSendWhatsapp(props: InvoiceSendWhatsappProps) {
	const { documents, whatsappServerUrl, invoiceType, showModal } = props;
	const { closeModal } = props;
	const [customerTel, setCustomerTel] = useState<string | undefined>();
	const [document, setDocument] = useState<string | undefined>();
	const [statusText, setStatus] = useState<string | undefined>();
	const [errorMessage, setErrorMessage] = useState<string | undefined>();

	const sendDocumento = async () => {
		if (!customerTel || !document || !/^9\d{8}$/.test(customerTel)) {
			console.log("Error en el número de teléfono");
			return;
		}
		setStatus("Envío en proceso...");
		setErrorMessage(undefined);

		try {
			const url =
				document === "PDF"
					? documents.pdf
					: document === "XML"
						? documents.xml
						: documents.cdr;

			const response = await fetch(`${whatsappServerUrl}/send`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					number: `+51${customerTel}`,
					documentUrl: url,
					mimetype: document,
					documentType: invoiceType,
				}),
			});
			if (response.ok) {
				setStatus("Documento enviado correctamente");
				setErrorMessage(undefined);
			} else {
				setErrorMessage("Error al enviar el documento");
				setStatus(undefined);
			}
		} catch (error: unknown) {
			setErrorMessage((error as Error).message);
			setStatus(undefined);
			console.log("Error al enviar el documento", error);
		}
	};

	const handleCloseModal = () => {
		setStatus(undefined);
		setErrorMessage(undefined);
		closeModal();
	};

	return (
		<>
			<Modal show={showModal}>
				<div className="modal-header">
					<h5 className="modal-title">Enviar comprobante por WhatsApp</h5>
					<button
						type="button"
						className="btn-close"
						onClick={handleCloseModal}
					></button>
				</div>
				<div className="modal-body">
					<form>
						<div className="form-group row">
							<label htmlFor="phone" className="col-sm-3 col-form-label">
								Número
							</label>
							<div className="col-sm-9">
								<input
									type="tel"
									className="form-control"
									id="phone"
									placeholder="Número de teléfono"
									maxLength={9}
									value={customerTel}
									onChange={(e) => setCustomerTel(e.target.value)}
								/>
							</div>
						</div>
						<fieldset className="form-group mt-3">
							<div className="row">
								<legend className="col-form-label col-sm-3 pt-0">
									Documento
								</legend>
								<div className="col-sm-9">
									<div className="form-check">
										<input
											className="form-check-input"
											type="radio"
											name="gridRadios"
											id="document-pdf"
											value="PDF"
											onChange={() => setDocument("PDF")}
											checked={document === "PDF"}
											disabled={documents.pdf === null}
										/>
										<label className="form-check-label" htmlFor="document-pdf">
											PDF
										</label>
									</div>
									<div className="form-check">
										<input
											className="form-check-input"
											type="radio"
											name="gridRadios"
											id="document-xml"
											value="XML"
											onChange={() => setDocument("XML")}
											checked={document === "XML"}
											disabled={documents.xml === null}
										/>
										<label className="form-check-label" htmlFor="document-xml">
											XML
										</label>
									</div>
								</div>
							</div>
						</fieldset>
					</form>
					<If condition={!!statusText}>
						<p className="mb-0">{statusText}</p>
					</If>
					<If condition={!!errorMessage}>
						<p className="text-danger mb-0">{errorMessage}</p>
					</If>
				</div>
				<div className="modal-footer">
					<Button mode="light" onClick={handleCloseModal}>
						Cerrar
					</Button>
					<Button
						disabled={!document || !/^9\d{8}$/.test(customerTel ?? "")}
						mode="success"
						onClick={sendDocumento}
					>
						Enviar
					</Button>
				</div>
			</Modal>
		</>
	);
}
