import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { CREATE_INVOICE } from "@/graphql/mutations";
import { useConfirmDialog } from "@/hooks/use-confirm-dialog";
import { formatDate } from "@/utils/date";

import { Customer } from "@/interfaces/customer";
import { InvoiceDetail } from "@/interfaces/invoice-detail";
import { InvoiceType } from "@/interfaces/invoice";

interface CreateInvoice {
	createInvoice: {
		id: string;
	};
}

export function useCreateInvoice() {
	const { confirm } = useConfirmDialog();
	const [create, { loading }] = useMutation<CreateInvoice>(CREATE_INVOICE);

	const createInvoice = useCallback(
		async (
			invoiceType: InvoiceType,
			customer: Customer,
			orderPaymentIds: string[],
			invoiceDetails: InvoiceDetail[],
			isCustom: boolean,
		) => {
			const document = invoiceType === "INVOICE" ? "factura" : "boleta";
			const resp = await confirm({
				title: "Confirmación",
				content: `¿Estás seguro de crear la ${document}?`,
			});

			if (!resp) return undefined;

			try {
				const details = isCustom
					? invoiceDetails.map(
							({ code, units, unitPrice, total, discount }) => ({
								code,
								units,
								unitPrice,
								total,
								discount,
							}),
						)
					: undefined;

				const response = await create({
					variables: {
						orderPaymentIds: orderPaymentIds,
						customerId: customer.id,
						details: details,
						type: invoiceType,
						email: customer.email,
						date: formatDate({ format: "en" }),
					},
				});

				return response.data?.createInvoice.id;
			} catch (error: unknown) {
				await confirm({
					type: "error",
					title: "Error",
					content: `Error al crear la ${document}. ${(error as Error).message}`,
					showCancelButton: false,
				});
			}
		},
		[create, confirm],
	);

	return { createInvoice, loading };
}
