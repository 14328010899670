import { FC, PropsWithChildren } from "react";

import Navbar from "../components/organisms/navbar";
import { useAuth } from "../store/use-auth";

const Layout: FC<PropsWithChildren> = ({ children }) => {
	const { name, role } = useAuth();

	return (
		<>
			<Navbar username={name} role={role} />
			<div className="container">{children}</div>
		</>
	);
};

export default Layout;
