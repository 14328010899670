const dateOptions: Intl.DateTimeFormatOptions = {
	year: "numeric",
	month: "2-digit",
	day: "2-digit",
};
const timeOptions: Intl.DateTimeFormatOptions = {
	hour: "2-digit",
	minute: "2-digit",
	hour12: true,
};
const peFormatter = new Intl.DateTimeFormat("es-PE", dateOptions);
const peHourFormatter = new Intl.DateTimeFormat("es-PE", timeOptions);
const caFormatter = new Intl.DateTimeFormat("en-CA", dateOptions);

export function formatDate({ date = new Date(), format = "pe" } = {}) {
	if (format === "pe") return peFormatter.format(date);

	return caFormatter.format(date);
}

export function dateToTime(date: Date): string {
	return peHourFormatter.format(date);
}
