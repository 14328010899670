import { roundToDecimals } from "@/utils/round-to-decimals";

import { CheckboxStates } from "@/interfaces/checkbox-statuses.enum";
import { OrderProductForm } from "@/interfaces/order-payment.interfaces";

export function updatePaymentOrderProductByChecked(
	product: OrderProductForm,
	checked: boolean,
): OrderProductForm {
	const { Checked, Empty } = CheckboxStates;
	const { productPrice } = product;

	const details = product.details.map((detail) => {
		const { discount, paidAmount, paidUnits } = detail;
		const unitsToPay = roundToDecimals(1 - paidUnits, 3);
		const amountToPay = roundToDecimals(
			productPrice - discount - paidAmount,
			1,
		);

		return {
			...detail,
			unitsToPay: checked ? unitsToPay : 0,
			amountToPay: checked ? amountToPay : 0,
		};
	});

	const notTotallyPaid = details.some((d) => d.paidUnits < 1);
	const checkboxState = checked && notTotallyPaid ? Checked : Empty;
	return {
		...product,
		checkboxState,
		unitsToPay: details.reduce(
			(acc, curr) => roundToDecimals(acc + curr.unitsToPay, 3),
			0,
		),
		amountToPay: details.reduce(
			(acc, curr) => roundToDecimals(acc + curr.amountToPay, 1),
			0,
		),
		details,
	};
}
