import { useState, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import ProductList from "@/components/organisms/product-list";
import OrderProducts from "@/components/organisms/order-products";
import Loading from "@/components/atoms/loading";
import { OrderDetail } from "@/components/templates/order/order-detail";
import {
	orderReducer,
	INITIAL_STATE,
	OrderReducerType,
} from "@/utils/order-reducer";
import { useOrderIsModified } from "@/hooks/use-order-is-modified";
import useSaveOrder from "@/hooks/use-save-order";
import useReleaseOrder from "@/hooks/use-release-order";
import { useCreateOrderPayment } from "@/hooks/use-create-payment-order";
import { OrderHeader } from "./order-header";
import { OrderControls } from "./order-controls";
import { If } from "@/components/atoms/if/if";
import { orderToOrderForm } from "@/pages/payment/order-to-order-form";
import { joinPaymentToPaymentOrder } from "@/pages/payment/join-payment-to-order";
import { OrderPayments } from "@/components/organisms/order-payments";
import { OrderPaymentSection } from "@/components/organisms/order-payment-section";

import { ProductCategory } from "@/interfaces/order.interface";
import { OrderStatus } from "@/interfaces/order-status.enum";
import { OrderForm } from "@/interfaces/order-payment.interfaces";
import { CheckboxStates } from "@/interfaces/checkbox-statuses.enum";
import { OrderPaymentStatus } from "@/interfaces/order-payment-status.enum";
import { TransactionMethod } from "@/interfaces/transaction-methos.enum";

interface OrderTemplateProps {
	order: OrderForm;
	categories: ProductCategory[];
}

const OrderTemplate: React.FC<OrderTemplateProps> = (props) => {
	const navigate = useNavigate();
	const [panel, setPanel] = useState(1);
	const [order, setOrder] = useReducer(
		orderReducer,
		orderToOrderForm(INITIAL_STATE),
	);
	const [originalOrder, setDbOrder] = useState(props.order);
	const [paymentMode, setPaymentMode] = useState(false);
	const { wasModified } = useOrderIsModified(order, originalOrder);
	const { saveOrder, loading: saveLoading } = useSaveOrder();
	const { createOrderPayment, loading: orderPaymentLoading } =
		useCreateOrderPayment();
	const { releaseOrder, loading: releaseLoading } = useReleaseOrder();

	useEffect(() => {
		const order = props.order;

		setOrder({ type: OrderReducerType.SET, payload: order });
		setDbOrder(window.structuredClone(order));

		return () => {
			setOrder({ type: OrderReducerType.RESET, payload: {} });
		};
	}, [props.order]);

	const handlePanel = () => {
		setPanel((current) => (current === 1 ? 0 : 1));
	};

	const handleCreateOrder = () => {
		saveOrder(order, (dbOrder: OrderForm) => {
			setOrder({ type: OrderReducerType.SET, payload: dbOrder });
			setDbOrder(window.structuredClone(dbOrder));

			navigate(`/order/${dbOrder.id}`, { replace: true });
		});
	};

	const handleReleaseOrder = async () => {
		const response = await releaseOrder(order.id!);
		if (!response) return;

		const newOrder = { ...order, status: OrderStatus.ATENDED };
		setOrder({ type: OrderReducerType.SET, payload: newOrder });
		setDbOrder(window.structuredClone(newOrder));
	};

	const handlePayOrder = (
		transactionMethod: TransactionMethod,
		tipAmount: number,
	) => {
		const { id, tables } = order;

		createOrderPayment({
			orderId: id!,
			transactionMethod,
			orderProductDetails: order.products,
			tipAmount,
			tables,
		}).then((response) => {
			if (!response) return;

			const p = joinPaymentToPaymentOrder(order.products, [response]);
			const isTotalPaid = p.every((p) => p.productSubTotal === p.paidAmount);
			const newOrder: OrderForm = {
				...order,
				checkboxState: CheckboxStates.Empty,
				paymentStatus: isTotalPaid
					? OrderPaymentStatus.PAID
					: OrderPaymentStatus.PARTIAL,
				products: p,
				payments: [...order.payments, response],
			};

			setOrder({ type: OrderReducerType.SET, payload: newOrder });
			setDbOrder(window.structuredClone(newOrder));
			setPaymentMode(false);
		});
	};

	return (
		<>
			{(saveLoading || orderPaymentLoading || releaseLoading) && <Loading />}
			<OrderHeader
				order={order}
				handlePanel={handlePanel}
				panel={panel}
				paymentMode={paymentMode}
			/>
			<If condition={panel === 1}>
				<OrderDetail order={order} />
				<OrderProducts
					reducer={setOrder}
					order={order}
					paymentMode={paymentMode}
				/>
				<OrderControls
					order={order}
					wasModified={wasModified}
					paymentMode={paymentMode}
					handleCreateOrder={handleCreateOrder}
					handleReleaseOrder={handleReleaseOrder}
					onPaymentModeClick={() => setPaymentMode(!paymentMode)}
				/>
			</If>
			<If condition={panel === 0}>
				<ProductList
					reducer={setOrder}
					details={order.products}
					categories={props.categories}
				/>
			</If>
			<If condition={paymentMode}>
				<OrderPaymentSection
					order={order}
					setPaymentMode={setPaymentMode}
					handlePayOrder={handlePayOrder}
					orderReducer={setOrder}
				/>
			</If>
			<If condition={order.payments.length > 0}>
				<OrderPayments order={order} orderReducer={setOrder} />
			</If>
		</>
	);
};

export default OrderTemplate;
