import { useContext, useRef, useState } from "react";
import { ConfirmDialogContext, ConfirmDialogProps, ConfirmDialog } from ".";

export function ConfirmDialogWithContext() {
	const [open, setOpen] = useState(false);
	const [confirmProps, setConfirmProps] = useState<ConfirmDialogProps>({});
	const resolveRef = useRef<(value: boolean) => void>(() => {});
	const { confirmRef } = useContext(ConfirmDialogContext);

	confirmRef &&
		(confirmRef.current = (props: ConfirmDialogProps = {}) => {
			setConfirmProps(props);

			return new Promise((resolve) => {
				setOpen(true);
				resolveRef.current = resolve;
			});
		});

	return (
		<ConfirmDialog
			open={open}
			onCancel={() => {
				resolveRef.current(false);
				setOpen(false);
			}}
			onConfirm={() => {
				resolveRef.current(true);
				setOpen(false);
			}}
			{...confirmProps}
		/>
	);
}
