import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";

import { GET_TABLES_WITH_ORDER, ORDER_CHANGE } from "../graphql";
import Layout from "./layout";
import Loading from "../components/atoms/loading";

import { WithAuthenticationProps } from "../hoc/withAuthentication";
import { Table } from "../interfaces/table.interface";
import { OrderType } from "../interfaces/order-type.enum";
import { OrderStatus } from "../interfaces/order-status.enum";

interface HomePageProps extends WithAuthenticationProps {}

const HomePage: React.FC<HomePageProps> = () => {
	const [tables, setTables] = useState<Table[]>([]);

	const { loading: l1, subscribeToMore } = useQuery(GET_TABLES_WITH_ORDER, {
		fetchPolicy: "no-cache",
		onCompleted: (data: { tables: Table[] }) => {
			setTables(data.tables);
		},
	});

	useEffect(() => {
		const unsubscribe = subscribeToMore({
			document: ORDER_CHANGE,
			updateQuery: (prev, { subscriptionData }) => {
				if (!subscriptionData.data) return prev;
				const tables: Table[] = subscriptionData.data.orderAdded;
				setTables((list) => {
					const copy = [...list];
					const [table] = tables;
					const { order } = table;

					if (order?.type === OrderType.PICKUP) {
						const index = copy.findIndex((c) => c.order?.id === order?.id);
						if (index === -1) {
							if (order.status === OrderStatus.REGISTERED) copy.push(table);
						} else {
							if (order.status === OrderStatus.ATENDED) copy.splice(index, 1);
						}
					} else {
						const index = copy.findIndex((t) => t.id === table.id);
						if (index >= 0) copy[index] = { ...copy[index], order };
					}
					return copy;
				});
			},
		});
		return () => {
			if (unsubscribe) unsubscribe();
		};
	}, [subscribeToMore]);

	return (
		<Layout>
			{l1 && <Loading />}
			<div className="my-2 d-flex justify-content-end">
				<Link to="/order" state={{}} className="btn btn-secondary">
					Para llevar
				</Link>
			</div>
			<div className="row">
				{tables.map((el) => {
					return (
						<div className="col-md-4 col-6" key={el.id || el.order?.id}>
							<div
								className={`card mb-2 ${
									el.order ? "text-white bg-success" : "text-dark bg-light"
								}`}
							>
								<div className={`card-body p-1`}>
									<div className="d-flex flex-md-row flex-column justify-content-between">
										<h5 className="card-title">Mesa {el.number}</h5>
										<span className="text-start text-md-end">
											{el.location}
										</span>
									</div>
									<p className="card-text">
										{el.order ? "REGISTRADO" : "LIBRE"}
									</p>
									<Link
										className="stretched-link"
										to={`/order/${el.order?.id ?? ""}`}
										state={{ table: el }}
									></Link>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</Layout>
	);
};

export default HomePage;
