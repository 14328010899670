import { ApolloProvider } from "@apollo/client";
import { client } from "./apollo";
import { useAuth } from "../store/use-auth";
import { useEffect } from "react";

export function ApolloClientProvider({
	children,
}: {
	children: React.ReactNode;
}) {
	const { token } = useAuth();

	useEffect(() => {
		client.defaultContext.token = token;
	}, [token]);

	return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
