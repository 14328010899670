import { Button } from "@/components/atoms/button/button";
import { Modal } from "@/components/organisms/modal/modal";
import { useCancelMoneyMovement } from "./use-cancel-money-movement";
import { If } from "@/components/atoms/if/if";
import { Loading } from "@/components/atoms/loading";

import { MoneyMovement } from "@/interfaces/money-movement";
import { TRANSACTION_METHODS } from "@/constants/transaction-method";
import { dateToTime, formatDate } from "@/utils/date";

interface MoneyMovementDetailsProps {
	closeModal: React.Dispatch<React.SetStateAction<boolean>>;
	moneyMovement: MoneyMovement | null;
	onMoneyMovementCancelled: () => void;
}

const DOCUMENT_TYPES: { [key: string]: string } = {
	INVOICE: "Factura",
	RECEIPT: "Boleta",
};

export function MoneyMovementDetails(props: MoneyMovementDetailsProps) {
	const { moneyMovement, closeModal, onMoneyMovementCancelled } = props;
	const { cancelMoneyMovement, loading } = useCancelMoneyMovement();

	const handleCancelMoneyMovement = async () => {
		if (!moneyMovement) return;

		const resp = await cancelMoneyMovement(moneyMovement.id);

		if (resp) onMoneyMovementCancelled();
	};

	if (!moneyMovement) return null;
	return (
		<>
			<If condition={loading} children={<Loading />} />
			<Modal show={true} onClose={() => closeModal(false)}>
				<div className="modal-header">
					<h5 className="modal-title">Detalles del movimiento</h5>
					<button
						type="button"
						className="btn-close"
						onClick={() => closeModal(false)}
					></button>
				</div>
				<div className="modal-body">
					<div className="row">
						<div className="col-12">
							<p className="mb-1">
								<strong>Fecha:</strong>{" "}
								{formatDate({ date: moneyMovement.date })}{" "}
								{dateToTime(moneyMovement.date)}
							</p>
							<p className="mb-1">
								<strong>Tipo:</strong> {moneyMovement.type.name}
							</p>
							<p className="mb-1">
								<strong>Área:</strong> {moneyMovement.area}
							</p>
							<p className="mb-1">
								<strong>Monto:</strong> {moneyMovement.amount}
							</p>
							<p className="mb-1">
								<strong>Categoría:</strong> {moneyMovement.category}
							</p>
							<p className="mb-1">
								<strong>Concepto:</strong> {moneyMovement.concept}
							</p>
							<p className="mb-1">
								<strong>Método:</strong>{" "}
								{TRANSACTION_METHODS[moneyMovement.transactionMethod]}
							</p>
							<p className="mb-1">
								<strong>Estado:</strong>{" "}
								{moneyMovement.isCancelled ? "Anulado" : "Registrado"}
							</p>
							<p className="mb-1">
								<strong>Tipo documento:</strong>{" "}
								{moneyMovement.invoiceType &&
									DOCUMENT_TYPES[moneyMovement.invoiceType]}
							</p>
							<p className="mb-1">
								<strong>RUC emisor:</strong> {moneyMovement.invoiceIssuerRuc}
							</p>
							<p className="mb-1">
								<strong>Número documento:</strong> {moneyMovement.invoiceNumber}
							</p>
							<p className="mb-1">
								<strong>Observaciones:</strong> {moneyMovement.observations}
							</p>
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<Button
						mode="danger"
						disabled={!moneyMovement.canBeCanceled}
						onClick={handleCancelMoneyMovement}
					>
						Anular
					</Button>
					<Button mode="secondary" onClick={() => closeModal(false)}>
						Cerrar
					</Button>
				</div>
			</Modal>
		</>
	);
}
