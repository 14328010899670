import { useState, useEffect } from "react";

import { TRANSACTION_METHODS } from "@/constants/transaction-method";
import { currencyFormat } from "@/utils/currency-format";

import { TransactionMethod } from "@/interfaces/transaction-methos.enum";
import { MoneyMovementSummary } from "@/interfaces/reports";

export function OrderReportSummary({ list }: { list: MoneyMovementSummary[] }) {
	const [summary, setSummary] = useState<MoneyMovementSummary[]>([]);
	const [outcommingCash, setOutcommingCash] = useState(0);
	const [outcommingTipCash, setOutcommingTipCash] = useState(0);

	const [tip, setTip] = useState(0);

	useEffect(() => {
		const incomming = list.filter((el) => el.type.name === "Ingreso");
		const outcomming = list.filter((el) => el.type.name === "Egreso");
		const food = incomming.filter((el) => el.area !== "TIP");
		const tip = incomming.filter((el) => el.area === "TIP");

		setTip(tip.reduce((a, b) => a + b.amount, 0));
		setOutcommingCash(
			outcomming
				.filter((el) => el.area === "RESTAURANT")
				.filter((el) => el.transactionMethod === TransactionMethod.CASH)
				.reduce((a, b) => a + b.amount, 0),
		);
		setOutcommingTipCash(
			outcomming
				.filter((el) => el.area === "TIP")
				.filter((el) => el.transactionMethod === TransactionMethod.CASH)
				.reduce((a, b) => a + b.amount, 0),
		);

		const discount: MoneyMovementSummary[] = [];

		[...tip]
			.filter((el) => el.transactionMethod !== TransactionMethod.CASH)
			.forEach((a) => {
				if (
					food.find((el) => el.transactionMethod === a.transactionMethod) ===
					undefined
				)
					food.push({
						date: new Date(),
						area: "RESTAURANT",
						category: "",
						concept: "",
						type: {
							id: "",
							name: "Ingreso",
							description: "",
						},
						amount: 0,
						transactionMethod: a.transactionMethod,
					});
				discount.push(a);
			});

		setSummary(
			food.map((el) => {
				if (el.transactionMethod === TransactionMethod.CASH) {
					return {
						...el,
						amount: el.amount - discount.reduce((a, b) => a + b.amount, 0),
					};
				}

				const add = discount
					.filter((a) => a.transactionMethod === el.transactionMethod)
					.reduce((a, b) => a + b.amount, 0);
				return {
					...el,
					amount: el.amount + add,
				};
			}),
		);
	}, [list]);

	return (
		<div className="col-12 col-md-4">
			<h3>Resumen</h3>
			<table className="table table-sm">
				<tbody>
					<tr>
						<td colSpan={4} className="text-center">
							Restaurant
						</td>
					</tr>
					{summary.map((el, id) => (
						<tr key={id}>
							<td>{TRANSACTION_METHODS[el.transactionMethod]}</td>
							<td className="text-end text-success">{el.amount}</td>
							<td className="text-end text-danger">
								{el.transactionMethod === TransactionMethod.CASH
									? outcommingCash
									: 0}
							</td>
							<td className="text-end">
								{el.transactionMethod === TransactionMethod.CASH
									? currencyFormat(el.amount - outcommingCash)
									: currencyFormat(el.amount)}
							</td>
						</tr>
					))}
					<tr>
						<td colSpan={4} className="text-center">
							Propinas
						</td>
					</tr>
					<tr>
						<td>Efectivo</td>
						<td className="text-end text-success">{tip}</td>
						<td className="text-end text-danger">{outcommingTipCash}</td>
						<td className="text-end">
							{currencyFormat(tip - outcommingTipCash)}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}
