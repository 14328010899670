import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";

import { SAVE_ORDER } from "../graphql/mutations";
import { useAuth } from "../store/use-auth";
import { useConfirmDialog } from "./use-confirm-dialog";
import { orderResponseToOrder } from "@/utils/order-response-to-order";
import { orderToOrderForm } from "@/pages/payment/order-to-order-form";
import { joinPaymentToPaymentOrder } from "@/pages/payment/join-payment-to-order";

import { OrderStatus } from "../interfaces/order-status.enum";
import { OrderForm } from "@/interfaces/order-payment.interfaces";

export default function useSaveOrder() {
	const [mutation, { loading }] = useMutation(SAVE_ORDER);
	const { confirm } = useConfirmDialog();
	const { id: userId } = useAuth();

	const saveOrder = async (
		order: OrderForm,
		callback: (order: OrderForm) => void,
	) => {
		const { id, products, observations, clientName, tables } = order;

		if (products.length < 1) {
			await confirm({
				title: "Alerta",
				content: "Debe agregar productos",
				type: "warning",
				showCancelButton: false,
			});
			return;
		}

		const message =
			order.status === OrderStatus.UNREGISTERED ? "guardar" : "actualizar";
		const resp = await confirm({
			content: `Se procederá a ${message} la orden`,
		});

		if (!resp) return;

		const newOrder = {
			id,
			tables: tables.map((t) => t.id),
			employeeId: userId,
			products: products.map((d) => ({
				id: d.id,
				quantity: d.quantity,
				productId: d.product.id,
				details: d.details.map((dd) => {
					const { id, delivered, discount, discountRate, options } = dd;
					return {
						id,
						delivered,
						discount,
						discountRate,
						options:
							typeof options === "string" ? options : JSON.stringify(options),
					};
				}),
			})),
			observations: observations,
			clientName: clientName,
			status: order.status,
		};

		const confirmMessage =
			order.status === OrderStatus.UNREGISTERED ? "guardada" : "actualizada";
		mutation({
			variables: {
				order: newOrder,
			},
		})
			.then(async (resp) => {
				await confirm({
					title: "Ok",
					content: `Orden ${confirmMessage} correctamente`,
					type: "success",
					showCancelButton: false,
				});
				const orderResponse = orderResponseToOrder(resp.data.saveOrder);
				const orderForm = orderToOrderForm(orderResponse);
				const products = joinPaymentToPaymentOrder(
					orderForm.products,
					orderForm.payments,
				);

				callback({
					...orderForm,
					products,
				});
			})
			.catch((err) => {
				confirm({
					title: "Ups, ocurrió un error",
					content: "Toma una captura de pantalla. " + err.message,
					type: "error",
					showCancelButton: false,
				});
				Sentry.captureException(err);
			});
	};

	return { loading, saveOrder };
}
