import { classNames } from "../../../utils/class-names";
import { Button } from "../button/button";

import classes from "./expanded-arrow.module.css";

interface ExpandedArrowProps {
	expanded: boolean;
	ariaLabel: string;
	setIsExpanded: (expanded: boolean) => void;
}

export function ExpandedArrow(props: ExpandedArrowProps) {
	const { expanded, setIsExpanded, ariaLabel } = props;

	return (
		<Button
			outline
			size="small"
			mode="secondary"
			onClick={() => setIsExpanded(!expanded)}
			aria-expanded={expanded}
			aria-label={ariaLabel}
		>
			<span
				className={classNames(classes["btn-arrow"], {
					[classes["collapsed"]]: !expanded,
				})}
			></span>
		</Button>
	);
}
