import { FC } from "react";

import { useAuthMutations } from "../../store/use-auth-mutations";
import { authService } from "../../services/firebase";
import { Button } from "./button/button";

const Logout: FC = () => {
	const { logout } = useAuthMutations();

	const handleClick = () => {
		authService.signOut().then(logout);
	};

	return (
		<Button className="dropdown-item" onClick={handleClick}>
			Salir
		</Button>
	);
};

export default Logout;
