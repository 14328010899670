/**
 * @enum {string}
 * @description
 * - RUC: Registro Único de Contribuyentes
 * - DNI: Documento Nacional de Identidad
 * - CE: Carnet de extranjería
 * - PASS: Pasaporte
 * - CD: Cédula diplomática
 */
export enum DocumentType {
	RUC = "RUC",
	DNI = "DNI",
	CE = "CE",
	PASS = "PASS",
	CD = "CD",
	SD = "-",
}
