import { gql } from "@apollo/client";

export const GET_TABLES_WITH_ORDER = gql`
	query getTablesAndOrders {
		tables {
			id
			number
			location
			order {
				id
				status
			}
		}
	}
`;

export const GET_PRODUCT_CATEGORIES_WITH_PRODUCTS = gql`
	query getProductCategoriesWithProducts {
		productCategories {
			id
			name
			subCategories {
				id
				name
				products {
					id
					name: displayName
					stock
					price {
						price
					}
				}
				productOptions {
					id
					name
					isDefault
				}
			}
		}
	}
`;

export const GET_ORDER_BY_TABLE_ID = gql`
	query getOrder(
		$tableId: ID
		$orderId: ID
		$statusCode: OrderStatus!
		$date: Date!
	) {
		order(
			tableId: $tableId
			orderId: $orderId
			statusCode: $statusCode
			date: $date
		) {
			id
			number
			status
			date
			total
			observations
			clientName
			type
			paymentStatus
			products {
				id
				quantity
				productPrice
				productSubTotal
				product {
					id
					name: displayName
				}
				details {
					id
					discountRate
					discount
					delivered
					options
				}
			}
			tables {
				id
				number
			}
			payments {
				id
				amount
				tipAmount
				date
				transactionMethod
				status
				invoiceId
				canBeCanceled
				orderProductDetails {
					id
					amount
					units
					orderProductId
				}
			}
		}
	}
`;

export const GET_ORDERS = gql`
	query getOrders($statusCode: OrderStatus, $date: Date!) {
		orders(statusCode: $statusCode, date: $date) {
			id
			status
			total
			date
			clientName
			paymentStatus
			type
			tables {
				id
				number
			}
		}
	}
`;

export const GET_ORDER_DAILY_REPORT = gql`
	query getDailyReport($date: Date!) {
		orderDailyReport(date: $date) {
			productName
			productPrice
			quantity
			subTotal
		}
	}
`;

export const GET_ORDER_PAYMENTS_SUMMARY = gql`
	query orderPaymentsSummary($date: Date!) {
		orderPaymentsSummary(date: $date) {
			transactionMethod
			amount
			type
		}
	}
`;

export const GET_ORDER_PAYMENTS_BY_IDS = gql`
	query orderPaymentsByIds($orderPaymentIds: [ID!]!) {
		orderPaymentsByIds(orderPaymentIds: $orderPaymentIds) {
			id
			amount
			tipAmount
			status
			date
			transactionMethod
			orderProductDetails {
				id
				units
				orderProductId
				productPrice
				amount
				discount
				product {
					id
					code
					name: displayName
					measurementUnit
				}
			}
		}
	}
`;

export const GET_INVOICE_BY_ID = gql`
	query getInvoiceById($invoiceId: ID!) {
		getInvoiceById(id: $invoiceId) {
			id
			serie
			number
			completeNumber
			pdfDocumentUrl
			cdrDocumentUrl
			xmlDocumentUrl
			date
			type
			currency
			total
			status
			isCancelled
			taxPercentage
			isAcceptedBySunat
			sunatDescription
			sunatNote
			sunatResponseCode
			sunatSoapError
			whatsappServerUrl
			customer {
				id
				fullName
				displayName
				documentType
				address
			}
			details {
				code
				discount
				name
				units
				unitPrice
				total
				measurementUnit
			}
			orderPayments {
				id
				date
				status
				amount
				transactionMethod
				orderId
			}
			cancellation {
				id
				reason
				number
				date
				sunatTicketNumber
				isAcceptedBySunat
				sunatDescription
				sunatNote
				sunatResponseCode
				sunatSoapError
				pdfDocumentUrl
				cdrDocumentUrl
				xmlDocumentUrl
			}
		}
	}
`;

export const SEARCH_CUSTOMER_INFO = gql`
	query searchCustomerInfo(
		$documentType: DocumentTypeEnum!
		$documentNumber: String!
	) {
		searchCustomerInfo(
			documentType: $documentType
			documentNumber: $documentNumber
		) {
			id
			fullName
			address
			phoneNumber
			documentType
			documentNumber
			email
		}
	}
`;

export const FIND_CUSTOMERS = gql`
	query findCustomers(
		$searchTerm: String!
		$invoiceType: InvoiceTypeEnum!
		$limit: UInt
	) {
		findCustomers(
			searchTerm: $searchTerm
			invoiceType: $invoiceType
			limit: $limit
		) {
			id
			displayName
			fullName
			documentType
			documentNumber
			address
			phoneNumber
			email
		}
	}
`;

export const GET_INVOICES_BY_RANGE_DATE = gql`
	query getInvoicesByRangeDate($startDate: Date!, $endDate: Date!) {
		getInvoicesByRangeDate(startDate: $startDate, endDate: $endDate) {
			id
			serie
			number
			completeNumber
			pdfDocumentUrl
			cdrDocumentUrl
			xmlDocumentUrl
			date
			type
			currency
			total
			status
			isCancelled
			taxPercentage
			isAcceptedBySunat
			sunatDescription
			sunatNote
			sunatResponseCode
			sunatSoapError
			whatsappServerUrl
			customer {
				id
				fullName
				displayName
				documentType
				address
			}
		}
	}
`;

export const GET_MONEY_MOVEMENTS_BY_RANGE_DATE = gql`
	query getMoneyMovementsByRangeDate(
		$startDate: Date!
		$endDate: Date!
		$moneyMovementTypeId: ID!
		$checkoutId: ID
	) {
		getMoneyMovementsByRangeDate(
			startDate: $startDate
			endDate: $endDate
			moneyMovementTypeId: $moneyMovementTypeId
			checkoutId: $checkoutId
		) {
			id
			date
			category
			concept
			amount
			transactionMethod
			area
			invoiceType
			invoiceIssuerRuc
			invoiceNumber
			isCancelled
			createdById
			observations
			canBeCanceled
			type {
				id
				name
			}
			checkout {
				id
				name
			}
		}
	}
`;

export const GET_MONEY_MOVEMENT_SUMMARIES = gql`
	query getMoneyMovementSummaries(
		$startDate: Date!
		$endDate: Date!
		$checkoutId: ID
	) {
		getMoneyMovementSummaries(
			startDate: $startDate
			endDate: $endDate
			checkoutId: $checkoutId
		) {
			date
			area
			amount
			category
			concept
			transactionMethod
			type {
				name
			}
		}
	}
`;
