import { CheckboxStates } from "@/interfaces/checkbox-statuses.enum";
import {
	OrderForm,
	OrderProductForm,
} from "@/interfaces/order-payment.interfaces";
import { Order } from "@/interfaces/order.interface";

export function orderToOrderForm(order: Order): OrderForm {
	const products = order.products.map((product): OrderProductForm => {
		const details = product.details.map((detail) => {
			return {
				...detail,
				paidUnits: 0,
				paidAmount: 0,
				unitsToPay: 0,
				amountToPay: 0,
			};
		});
		return {
			...product,
			checkboxState: CheckboxStates.Empty,
			paidUnits: 0,
			paidAmount: 0,
			unitsToPay: 0,
			amountToPay: 0,
			details,
		};
	});

	return {
		...order,
		checkboxState: CheckboxStates.Empty,
		products,
	};
}
