import { FC } from "react";

interface CollapseProps {
	children: React.ReactNode;
	classNames: string;
	isOpen: boolean;
}

const Collapse: FC<CollapseProps> = ({ classNames, children, isOpen }) => {
	return (
		<div className={`${classNames} collapse ${isOpen ? "show" : ""}`}>
			{children}
		</div>
	);
};

export default Collapse;
