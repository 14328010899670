/// <reference types="react/canary" />
import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";
import AuthProvider from "./store/auth";
import { ConfirmDialogProvider } from "./components/organisms/confirm-dialog/confirm-dialog-context-provider";
import { ApolloClientProvider } from "./graphql/apollo-client-provider";
import { ENVIRONMENT, isDevelopment } from "./utils/environment";

import "bootstrap/dist/css/bootstrap.css";

Sentry.init({
	dsn: import.meta.env.VITE_APP_SENTRY_DSN,
	integrations: [
		new Sentry.BrowserTracing({
			tracePropagationTargets: [
				/^https:\/\/laladrillera-eus2\.azurewebsites\.net\/graphql/,
			],
		}),
	],
	environment: ENVIRONMENT,
	tracePropagationTargets: [
		/^https:\/\/laladrillera-eus2\.azurewebsites\.net\/graphql/,
	],
	// Performance Monitoring
	tracesSampleRate: isDevelopment ? 1.0 : 0.5,
	release: "toma-pedidos@" + import.meta.env.VITE_APP_RELEASE_VERSION,
});

const container = document.getElementById("root") as HTMLElement;

const root = createRoot(container);
root.render(
	<React.StrictMode>
		<AuthProvider>
			<ConfirmDialogProvider>
				<ApolloClientProvider>
					<Router>
						<App />
					</Router>
				</ApolloClientProvider>
			</ConfirmDialogProvider>
		</AuthProvider>
	</React.StrictMode>,
);
