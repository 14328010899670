import { useState } from "react";
import { useQuery } from "@apollo/client";

import Layout from "../layout";
import { formatDate } from "@/utils/date";
import { GET_MONEY_MOVEMENTS_BY_RANGE_DATE } from "@/graphql/queries";
import { Input } from "@/components/atoms/input/input";
import { If } from "@/components/atoms/if/if";
import { Loading } from "@/components/atoms/loading";
import { Button } from "@/components/atoms/button/button";
import { TRANSACTION_METHODS } from "@/constants/transaction-method";
import { useAuth } from "@/store/use-auth";
import { CHECKOUTS } from "@/constants/checkout";
import { MoneyMovementForm } from "./money-movement-form";
import { MONEY_MOVEMENT_TYPES } from "@/constants/money-movement";

import {
	MoneyMovement,
	MoneyMovementResponse,
} from "@/interfaces/money-movement";
import { UserRole } from "@/interfaces/user-role.enum";
import { MoneyMovementDetails } from "./money-movement-details";

const TODAY = formatDate({ format: "en" });
const moneyMovementResponseToMoneyMovement = (
	mmr: MoneyMovementResponse[],
): MoneyMovement[] => mmr.map((ir) => ({ ...ir, date: new Date(ir.date) }));

export function MoneyMovementsPage() {
	const { role } = useAuth();
	const [movements, setMovements] = useState<MoneyMovement[]>([]);
	const [startDate, setStartDate] = useState(formatDate({ format: "en" }));
	const [endDate, setEndDate] = useState(formatDate({ format: "en" }));
	const [type, setType] = useState(MONEY_MOVEMENT_TYPES[1].id);
	const [showModal, setShowModal] = useState(false);
	const [selectedMovement, setSelectedMovement] =
		useState<MoneyMovement | null>(null);

	const { loading, refetch } = useQuery<{
		getMoneyMovementsByRangeDate: MoneyMovementResponse[];
	}>(GET_MONEY_MOVEMENTS_BY_RANGE_DATE, {
		variables: {
			startDate,
			endDate,
			checkoutId: role === UserRole.CAJA ? CHECKOUTS[0].id : undefined,
			moneyMovementTypeId: type,
		},
		fetchPolicy: "no-cache",
		onCompleted: ({ getMoneyMovementsByRangeDate }) => {
			const movements = moneyMovementResponseToMoneyMovement(
				getMoneyMovementsByRangeDate,
			);
			setMovements(movements);
		},
	});

	return (
		<Layout>
			<If condition={loading} children={<Loading />} />
			<h1>Movimientos</h1>
			<div className="mb-3 row g-2">
				<div className="form-floating mb-3 col-6 col-sm-3">
					<select
						className="form-select"
						value={type}
						onChange={(e) => setType(e.target.value)}
					>
						<option value={MONEY_MOVEMENT_TYPES[0].id}>
							{MONEY_MOVEMENT_TYPES[0].name}
						</option>
						<option value={MONEY_MOVEMENT_TYPES[1].id}>
							{MONEY_MOVEMENT_TYPES[1].name}
						</option>
					</select>
				</div>
				<div className="form-floating mb-3 col-6 col-sm-3">
					<Input
						type="date"
						value={startDate}
						max={endDate}
						onChange={(e) => setStartDate(e.target.value)}
						label="Fecha de inicio"
						labelPlacement="bottom"
					/>
				</div>
				<div className="form-floating mb-3 col-6 col-sm-3">
					<Input
						type="date"
						value={endDate}
						min={startDate}
						max={TODAY}
						onChange={(e) => setEndDate(e.target.value)}
						label="Fecha de fin"
						labelPlacement="bottom"
					/>
				</div>
			</div>
			<div className="mb-3">
				<Button onClick={() => setShowModal(true)}>Crear movimiento</Button>
			</div>
			<section className="table-responsive">
				<table className="table table-sm">
					<thead>
						<tr>
							<th>Fecha</th>
							<th>Tipo</th>
							<th>Categoría</th>
							<th>Concepto</th>
							<th>Monto</th>
							<th>Método</th>
							<th>Área</th>
							<th>Estado</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						<If condition={movements.length === 0}>
							<tr>
								<td colSpan={9} className="text-center">
									No hay movimientos por mostrar
								</td>
							</tr>
						</If>
						{movements.map((i) => (
							<tr key={i.id}>
								<td>{formatDate({ date: i.date })}</td>
								<td>{i.type.name}</td>
								<td>{i.category}</td>
								<td>{i.concept}</td>
								<td>{i.amount}</td>
								<td>{TRANSACTION_METHODS[i.transactionMethod]}</td>
								<td>{i.area}</td>
								<td>{i.isCancelled ? "ANULADO" : "REGISTRADO"}</td>
								<td>
									<Button
										className="btn btn-secondary btn-sm"
										onClick={() => setSelectedMovement(i)}
									>
										Ver
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</section>
			<MoneyMovementForm
				setShowModal={setShowModal}
				showModal={showModal}
				onMoneyMovementCreated={() => {
					refetch().then(({ data }) => {
						setShowModal(false);
						const movements = moneyMovementResponseToMoneyMovement(
							data.getMoneyMovementsByRangeDate,
						);
						setMovements(movements);
					});
				}}
			/>
			<MoneyMovementDetails
				closeModal={() => setSelectedMovement(null)}
				onMoneyMovementCancelled={() => {
					refetch().then(({ data }) => {
						setSelectedMovement(null);
						const movements = moneyMovementResponseToMoneyMovement(
							data.getMoneyMovementsByRangeDate,
						);
						setMovements(movements);
					});
				}}
				moneyMovement={selectedMovement}
			/>
		</Layout>
	);
}
