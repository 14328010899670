import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";

import { RELEASE_ORDER } from "@/graphql/mutations";
import { useConfirmDialog } from "./use-confirm-dialog";

export default function useReleaseOrder() {
	const [mutation, { loading }] = useMutation(RELEASE_ORDER);
	const { confirm } = useConfirmDialog();

	const releaseOrder = async (orderId: string) => {
		const resp = await confirm({
			content: "Se liberará la orden",
		});

		if (!resp) return false;

		try {
			const { data } = await mutation({
				variables: {
					orderId,
				},
			});
			if (!data.releaseOrder) throw new Error("No se pudo liberar la orden");

			await confirm({
				title: "Ok",
				content: "Orden liberada correctamente",
				type: "success",
				showCancelButton: false,
			});

			return true;
		} catch (error: unknown) {
			await confirm({
				title: "Ups, ocurrió un error",
				content: (error as Error).message,
				type: "error",
				showCancelButton: false,
			});
			Sentry.captureException(error);
			return false;
		}
	};

	return { loading, releaseOrder };
}
