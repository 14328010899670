import { forwardRef } from "react";
import { classNames } from "../../../utils/class-names";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	mode?:
		| "primary"
		| "secondary"
		| "success"
		| "danger"
		| "warning"
		| "info"
		| "dark"
		| "light";
	size?: "small" | "medium" | "large";
	outline?: boolean;
	active?: boolean;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const sizeMapper = {
	small: "btn-sm",
	medium: undefined,
	large: "btn-lg",
};

export const Button = forwardRef(function Button(
	{
		children,
		mode = "primary",
		size = "medium",
		outline = false,
		onClick,
		type = "button",
		active = false,
		className = undefined,
		...rest
	}: ButtonProps,
	ref: React.Ref<HTMLButtonElement>,
) {
	const typeClassName = outline ? `btn-outline-${mode}` : `btn-${mode}`;
	const sizeClassName = sizeMapper[size];
	const activeClassName = active ? "active" : undefined;

	return (
		<button
			ref={ref}
			className={classNames(
				"btn",
				className,
				typeClassName,
				sizeClassName,
				activeClassName,
			)}
			onClick={(e) => onClick && onClick(e)}
			type={type}
			{...rest}
		>
			{children}
		</button>
	);
});
