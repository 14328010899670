import { useState } from "react";

import { Button } from "../../atoms/button/button";
import { classNames } from "../../../utils/class-names";

import classes from "./login.module.css";
import logo from "../../../images/logo.jpg";

const INITIAL_STATE = { email: "", password: "" };
interface LoginTemplateProps {
	handleClick: (email: string, password: string) => void;
	message: string;
}

export default function LoginTemplate({
	handleClick,
	message,
}: LoginTemplateProps) {
	const [state, setstate] = useState(INITIAL_STATE);
	const [errorMessage, setErrorMessage] = useState(message);

	const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		const { email, password } = state;
		if (!email || !password) {
			setErrorMessage("Debe ingresar datos");
			return;
		}
		handleClick(email, password);
	};

	return (
		<form className={classNames(classes["form-signin"], "mt-5")}>
			<img className="mb-4" src={logo} alt="logo" height="90px" />
			<h1 className="h3 mb-3 fw-normal">Iniciar Sesión</h1>
			<label htmlFor="inputEmail" className="visually-hidden">
				Email
			</label>
			<input
				type="email"
				id="inputEmail"
				className={classNames(classes["form-control"], "form-control")}
				placeholder="Email"
				value={state.email}
				onChange={(e) => setstate({ ...state, email: e.target.value })}
				required
			/>
			<label htmlFor="inputPassword" className="visually-hidden">
				Contraseña
			</label>
			<input
				type="password"
				id="inputPassword"
				className={classNames(classes["form-control"], "form-control", {
					"mb-0": !!errorMessage,
					"mb-3": !errorMessage,
				})}
				placeholder="Contraseña"
				value={state.password}
				onChange={(e) => setstate({ ...state, password: e.target.value })}
				required
			/>

			{errorMessage && <p className="text-danger mb-3">{errorMessage}</p>}

			<Button mode="secondary" size="large" type="submit" onClick={onClick}>
				Ingresar
			</Button>
			<p className="mt-5 mb-3 text-muted">
				&copy; 1994-{new Date().getFullYear()}
			</p>
		</form>
	);
}
