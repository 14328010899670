import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Layout from "../layout";
import { currencyFormat } from "@/utils/currency-format";
import { formatDate } from "@/utils/date";
import { GET_INVOICES_BY_RANGE_DATE } from "@/graphql/queries";
import { invoiceResponseToInvoice } from "../invoice/invoice-response-to-invoice";
import { INVOICE_STATUSES } from "@/constants/invoice-statuses";
import { Input } from "@/components/atoms/input/input";
import { If } from "@/components/atoms/if/if";
import { Loading } from "@/components/atoms/loading";

import { Invoice, InvoiceResponse } from "@/interfaces/invoice";

const TODAY = formatDate({ format: "en" });
const FROM_PARAM = "from";
const TO_PARAM = "to";

function getInitialDate(paramDate: string | null): string {
	if (!paramDate) return TODAY;

	const date = new Date(paramDate);
	if (date.toString() === "Invalid Date") return TODAY;

	return paramDate;
}

export function InvoicesPage() {
	const [searchParams, setUrlSearchParams] = useSearchParams();
	const from = getInitialDate(searchParams.get(FROM_PARAM));
	const to = getInitialDate(searchParams.get(TO_PARAM));
	const [invoices, setInvoices] = useState<Invoice[]>([]);
	const [startDate, setStartDate] = useState(from);
	const [endDate, setEndDate] = useState(to);

	const { loading } = useQuery<{ getInvoicesByRangeDate: InvoiceResponse[] }>(
		GET_INVOICES_BY_RANGE_DATE,
		{
			variables: {
				startDate,
				endDate,
			},
			fetchPolicy: "no-cache",
			onCompleted: (data) => {
				const invoices = data.getInvoicesByRangeDate.map((ir) =>
					invoiceResponseToInvoice({
						...ir,
						orderPayments: ir.orderPayments ? ir.orderPayments : [],
					}),
				);
				setInvoices(invoices);
			},
		},
	);

	return (
		<Layout>
			<If condition={loading} children={<Loading />} />
			<h1>Invoices</h1>
			<div className="mb-3 row g-2">
				<div className="form-floating mb-3 col-6 col-sm-4">
					<Input
						type="date"
						value={startDate}
						max={endDate}
						onChange={(e) => {
							setStartDate(e.target.value);
							setUrlSearchParams({
								[FROM_PARAM]: e.target.value,
								[TO_PARAM]: to,
							});
						}}
						label="Fecha de inicio"
						labelPlacement="bottom"
					/>
				</div>
				<div className="form-floating mb-3 col-6 col-sm-4">
					<Input
						type="date"
						value={endDate}
						min={startDate}
						max={TODAY}
						onChange={(e) => {
							setEndDate(e.target.value);
							setUrlSearchParams({
								[FROM_PARAM]: from,
								[TO_PARAM]: e.target.value,
							});
						}}
						label="Fecha de fin"
						labelPlacement="bottom"
					/>
				</div>
			</div>
			<section className="table-responsive">
				<table className="table table-sm">
					<thead>
						<tr>
							<th>Serie</th>
							<th>Tipo</th>
							<th>Cliente</th>
							<th>Total</th>
							<th>Fecha emisión</th>
							<th>Estado SUNAT</th>
							<th>Estado</th>
							<th>Acciones</th>
						</tr>
					</thead>
					<tbody>
						<If condition={invoices.length === 0}>
							<tr>
								<td colSpan={8} className="text-center">
									No hay facturas por mostrar
								</td>
							</tr>
						</If>
						{invoices.map((i) => (
							<tr key={i.id}>
								<td>{i.completeNumber}</td>
								<td>{i.type === "INVOICE" ? "Factura" : "Boleta"}</td>
								<td>{i.customer.fullName}</td>
								<td>{currencyFormat(i.total)}</td>
								<td>{formatDate({ date: i.date })}</td>
								<td>{i.isAcceptedBySunat ? "ACEPTADO" : "PENDIENTE"}</td>
								<td>
									{i.isCancelled
										? "ANULADO"
										: INVOICE_STATUSES[
												i.status as keyof typeof INVOICE_STATUSES
											]}
								</td>
								<td>
									<Link
										className="btn btn-secondary btn-sm"
										to={`/invoice/${i.id}`}
									>
										Ver
									</Link>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</section>
		</Layout>
	);
}
