import { gql } from "@apollo/client";

export const CREATE_ORDER_PAYMENT = gql`
	mutation createOrderPayment(
		$orderPayment: OrderPaymentInput!
		$tableIds: [ID!]!
	) {
		createOrderPayment(orderPayment: $orderPayment, tableIds: $tableIds) {
			id
			date
			transactionMethod
			amount
			tipAmount
			orderId
			status
			canBeCanceled
			orderProductDetails {
				id
				units
				amount
				orderProductId
			}
		}
	}
`;
