import { OrderPaymentPreview } from "@/pages/payment/order-payment-preview";
import { OrderPaymentSummary } from "@/pages/payment/order-payment-summary";
import { OrderReducerAction, OrderReducerType } from "@/utils/order-reducer";

import { CheckboxStates } from "@/interfaces/checkbox-statuses.enum";
import { TransactionMethod } from "@/interfaces/transaction-methos.enum";
import { OrderForm } from "@/interfaces/order-payment.interfaces";

interface OrderPaymentSectionProps {
	order: OrderForm;
	handlePayOrder: (
		transactionMethod: TransactionMethod,
		tipAmount: number,
	) => void;
	setPaymentMode: (paymentMode: boolean) => void;
	orderReducer: React.Dispatch<OrderReducerAction>;
}

export function OrderPaymentSection(props: OrderPaymentSectionProps) {
	const { order, handlePayOrder, setPaymentMode, orderReducer } = props;

	const handleCancelClick = () => {
		setPaymentMode(false);
		orderReducer({
			type: OrderReducerType.SELECT_ALL_FOR_PAYMENT,
			payload: { checkboxState: CheckboxStates.Empty },
		});
	};

	return (
		<div className="row mt-2">
			<div className="col-12 col-sm-8">
				<OrderPaymentPreview paymentOrderProducts={order.products} />
			</div>
			<div className="col-12 col-sm-4 mt-2 mt-sm-0">
				<OrderPaymentSummary
					orderTotal={order.total}
					orderProducts={order.products}
					onPayOrder={handlePayOrder}
					onCancelButtonClick={handleCancelClick}
				/>
			</div>
		</div>
	);
}
