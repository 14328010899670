import { useState } from "react";

import { Button } from "@/components/atoms/button/button";
import { If } from "@/components/atoms/if/if";
import { Input } from "@/components/atoms/input/input";
import { ExpandedArrow } from "@/components/atoms/expanded-arrow/expanded-arrow";
import { currencyFormat } from "@/utils/currency-format";
import { Checkbox } from "./checkbox";
import {
	getPaymentOrderProductDetailStatusBadge,
	getPaymentOrderProductStatusBadge,
} from "@/pages/payment/payment-status-badge";
import { Radio } from "./radio";
import { truncateToSingleDecimal } from "@/utils/truncate-to-single-decimal";
import { roundToDecimals } from "@/utils/round-to-decimals";

import {
	OrderForm,
	OrderProductForm,
} from "@/interfaces/order-payment.interfaces";
import { CheckboxStates } from "@/interfaces/checkbox-statuses.enum";

interface OrderProductProps {
	order: OrderForm;
	orderProduct: OrderProductForm;
	isModifiable: boolean;
	paymentMode: boolean;
	add: (op: OrderProductForm) => void;
	remove: (op: OrderProductForm, index: number) => void;
	update: (
		op: OrderProductForm,
		index: number,
		type: "discount" | "discountRate",
		value: string,
	) => void;
	handleSelectOneToPay: (op: OrderProductForm, checked: boolean) => void;
	onOrderProductChange: (orderProduct: OrderProductForm) => void;
}

const OrderProduct: React.FC<OrderProductProps> = (props) => {
	const { orderProduct: op, isModifiable, paymentMode } = props;
	const { add, remove, update, handleSelectOneToPay, onOrderProductChange } =
		props;
	const [isOpen, setIsOpen] = useState(false);

	const handleAmountToPayChange = (
		amountToPay: number,
		orderProductDetailId: string,
	) => {
		amountToPay = truncateToSingleDecimal(amountToPay);

		const details = op.details.map((detail) => {
			const { discount } = detail;

			if (detail.id === orderProductDetailId) {
				const { productPrice } = op;
				const unitsToPay = roundToDecimals(
					amountToPay / (productPrice - discount),
					3,
				);

				return {
					...detail,
					unitsToPay,
					amountToPay,
				};
			}

			return detail;
		});

		const accumulate = details.reduce(
			(acc, curr) => curr.amountToPay + curr.paidAmount + acc,
			0,
		);
		const totalToPay = details.reduce((acc, curr) => curr.amountToPay + acc, 0);

		const checkboxState =
			totalToPay === 0
				? CheckboxStates.Empty
				: accumulate === op.productSubTotal
					? CheckboxStates.Checked
					: CheckboxStates.Indeterminate;

		const newOrderProduct: OrderProductForm = {
			...op,
			unitsToPay: details.reduce(
				(acc, curr) => roundToDecimals(acc + curr.unitsToPay, 3),
				0,
			),
			amountToPay: details.reduce(
				(acc, curr) => roundToDecimals(acc + curr.amountToPay, 1),
				0,
			),
			details,
			checkboxState,
		};

		onOrderProductChange(newOrderProduct);
	};

	return (
		<li className="list-group-item p-0">
			<div className="d-flex justify-content-between align-items-center row px-3 py-1">
				<div className="col-6 px-0 d-flex">
					<div className="form-check">
						<If condition={paymentMode}>
							<Checkbox
								className="mb-auto mt-1"
								name={op.product.name}
								checked={op.checkboxState === CheckboxStates.Checked}
								undeterminated={
									op.checkboxState === CheckboxStates.Indeterminate
								}
								disabled={op.paidUnits === op.quantity}
								onChange={(e) => handleSelectOneToPay(op, e.target.checked)}
							/>
						</If>
					</div>
					<p className="mb-0 text-success">
						{op.product.name}
						{getPaymentOrderProductStatusBadge(op)}
					</p>
				</div>
				<div className="col-1 px-0">
					<Button
						disabled={paymentMode || !isModifiable}
						mode="success"
						outline
						size="small"
						onClick={() => add(op)}
					>
						+
					</Button>
				</div>
				<div className="col-4 px-0 text-end">
					<span className="">
						{`${op.quantity} x${op.productPrice} = ${currencyFormat(op.productSubTotal)}`}
					</span>
				</div>
				<div className="col-1 px-0 text-end">
					<ExpandedArrow
						expanded={isOpen}
						setIsExpanded={setIsOpen}
						ariaLabel={`Detalles del producto: ${op.product.name.toLowerCase()}`}
					/>
				</div>
			</div>

			<div className={`collapse ${isOpen ? "show" : ""} px-2`}>
				<table className="table table-sm table-borderless table-striped mb-1">
					<thead>
						<tr>
							<td>Pago</td>
							<If condition={paymentMode} children={<td>Sel.</td>} />
							<If
								condition={paymentMode}
								children={<td title="Monton restante">Rest.</td>}
							/>
							<td>Desc</td>
							<td>%</td>
							<td className="text-end">Total</td>
							<td></td>
						</tr>
					</thead>
					<tbody>
						{op.details.map((dd, i) => {
							const restToPay = roundToDecimals(
								op.productPrice - dd.discount - dd.paidAmount,
								1,
							);

							return (
								<tr key={dd.id || dd.registrationDate.getTime()}>
									<td>{getPaymentOrderProductDetailStatusBadge(dd, op)}</td>
									<If condition={paymentMode}>
										<td>
											<div className="input-group">
												<Radio
													asButton
													buttonClassName="btn-outline-secondary rounded-start"
													disabled={restToPay === 0}
													checked={
														dd.amountToPay !== 0 && dd.amountToPay === restToPay
													}
													onClick={() => {
														if (restToPay === dd.amountToPay) {
															handleAmountToPayChange(0, dd.id!);
														}
													}}
													onChange={() => {
														handleAmountToPayChange(restToPay, dd.id!);
													}}
													label="Todo"
												/>
												<Radio
													asButton
													buttonClassName="btn-outline-secondary"
													disabled={dd.paidAmount > 0}
													checked={
														truncateToSingleDecimal(
															(op.productPrice - dd.discount) / 2,
														) ===
														dd.amountToPay - dd.paidAmount
													}
													onClick={() => {
														if (
															truncateToSingleDecimal(
																(op.productPrice - dd.discount) / 2,
															) === dd.amountToPay
														) {
															handleAmountToPayChange(0, dd.id!);
														}
													}}
													onChange={() => {
														handleAmountToPayChange(
															(op.productPrice - dd.discount) / 2,
															dd.id!,
														);
													}}
													label="Mitad"
												/>
												<Input
													type="number"
													step={0.1}
													disabled={restToPay === 0}
													max={restToPay}
													min={0}
													value={dd.amountToPay.toString()}
													onChange={(e) =>
														handleAmountToPayChange(
															Number(e.target.value),
															dd.id!,
														)
													}
												/>
											</div>
										</td>
									</If>
									<If condition={paymentMode}>
										<td>
											{roundToDecimals(
												op.productPrice -
													dd.discount -
													dd.paidAmount -
													dd.amountToPay,
												1,
											)}
										</td>
									</If>
									<td className="p-1">
										<Input
											disabled={
												dd.paidUnits > 0 || paymentMode || !isModifiable
											}
											className="p-1"
											type="number"
											onChange={(e) =>
												update(op, i, "discount", e.target.value)
											}
											value={dd.discount.toString()}
											min={0}
											max={op.productPrice}
											step={0.1}
										/>
									</td>
									<td className="p-1">
										<Input
											disabled={
												dd.paidUnits > 0 || paymentMode || !isModifiable
											}
											className="p-1"
											type="number"
											onChange={(e) =>
												update(op, i, "discountRate", e.target.value)
											}
											value={dd.discountRate.toString()}
											min={0}
											max={100}
											step={1}
										/>
									</td>
									<td className="text-end">
										{currencyFormat(op.productPrice - dd.discount)}
									</td>
									<td>
										<Button
											disabled={
												dd.paidUnits > 0 || paymentMode || !isModifiable
											}
											mode="danger"
											size="small"
											onClick={() => remove(op, i)}
										>
											x
										</Button>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</li>
	);
};

export default OrderProduct;
