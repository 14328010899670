import { InvoiceDetail } from "@/interfaces/invoice-detail";
import { OrderPaymentResponse } from "@/interfaces/order-payment.interfaces";

export const reduceOrderPayments = (orderPayments: OrderPaymentResponse[]) => {
	const orderProductDetails = orderPayments
		.map((x) => x.orderProductDetails)
		.flat();

	const reducedPaymentDetails = orderProductDetails.reduce(
		(
			obj: {
				[key: string]: InvoiceDetail;
			},
			el,
		) => {
			if (el.product === undefined) return obj;

			obj[el.product.id] = obj[el.product.id] ?? {
				code: el.product.code,
				name: el.product.name,
				units: 0,
				unitPrice: el.productPrice ?? 0,
				total: 0,
				discount: 0,
				measurementUnit: el.product.measurementUnit,
			};
			obj[el.product.id].units += el.units;
			obj[el.product.id].total += el.amount;
			obj[el.product.id].discount += el.discount;

			return obj;
		},
		{},
	);

	return Object.values(reducedPaymentDetails);
};
