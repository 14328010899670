import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { CREATE_MONEY_MOVEMENT } from "@/graphql/mutations";
import { useConfirmDialog } from "@/hooks/use-confirm-dialog";

import { MoneyMovementForm } from "@/interfaces/money-movement";

interface CreateMoneyMovement {
	createMoneyMovement: boolean;
}

export function useCreateMoneyMovement() {
	const { confirm } = useConfirmDialog();
	const [create, { loading }] = useMutation<CreateMoneyMovement>(
		CREATE_MONEY_MOVEMENT,
	);

	const createMoneyMovement = useCallback(
		async (movement: MoneyMovementForm) => {
			const resp = await confirm({
				title: "Confirmación",
				content: `¿Estás seguro de crear el movimiento?`,
			});

			if (!resp) return undefined;

			try {
				const response = await create({
					variables: {
						moneyMovement: {
							date: movement.date,
							typeId: movement.typeId,
							checkoutId: movement.checkoutId,
							category: movement.category === "" ? null : movement.category,
							concept: movement.concept,
							amount: movement.amount,
							transactionMethod: movement.transactionMethod,
							area: movement.area,
							invoiceType:
								movement.invoiceType === "" ? null : movement.invoiceType,
							invoiceIssuerRuc:
								movement.invoiceIssuerRuc === ""
									? null
									: movement.invoiceIssuerRuc,
							invoiceNumber: movement.invoiceNumber,
							observations:
								movement.observations === "" ? null : movement.observations,
						},
					},
				});

				return response.data?.createMoneyMovement;
			} catch (error: unknown) {
				await confirm({
					type: "error",
					title: "Error",
					content: `Error. ${(error as Error).message}`,
					showCancelButton: false,
				});
			}
		},
		[create, confirm],
	);

	return { createMoneyMovement, loading };
}
