import { useMutation } from "@apollo/client";
import * as Sentry from "@sentry/react";

import { useConfirmDialog } from "@/hooks/use-confirm-dialog";
import { CREATE_ORDER_PAYMENT } from "@/pages/payment/create-order-payment-mutation";

import {
	OrderPayment,
	OrderPaymentResponse,
	OrderProductForm,
} from "@/interfaces/order-payment.interfaces";
import { Table } from "@/interfaces/table.interface";
import { TRANSACTION_METHODS } from "@/constants/transaction-method";
import { TransactionMethod } from "@/interfaces/transaction-methos.enum";

export function useCreateOrderPayment() {
	const { confirm } = useConfirmDialog();
	const [mutation, { loading }] = useMutation<{
		createOrderPayment: OrderPaymentResponse;
	}>(CREATE_ORDER_PAYMENT);

	const createOrderPayment = async (payload: {
		orderId: string;
		transactionMethod: TransactionMethod;
		orderProductDetails: OrderProductForm[];
		tipAmount: number;
		tables: Table[];
	}): Promise<OrderPayment | void> => {
		const transactionMethodText =
			TRANSACTION_METHODS[payload.transactionMethod];

		const resp = await confirm({
			content: `Se procederá a pagar la orden con ${transactionMethodText}, ¿desea continuar?`,
		});

		if (!resp) return;

		const {
			orderProductDetails,
			orderId,
			transactionMethod,
			tables,
			tipAmount,
		} = payload;

		const list = orderProductDetails
			.filter((p) => p.unitsToPay > 0)
			.map((product) => [
				...product.details
					.filter((d) => d.unitsToPay > 0)
					.map((d) => ({
						id: d.id!,
						amount: d.amountToPay,
						units: d.unitsToPay,
						orderProductId: product.id!,
					})),
			])
			.flat();
		if (list.length === 0)
			throw new Error(
				"No products selected to pay, please select at least one",
			);

		try {
			const { data } = await mutation({
				variables: {
					orderPayment: {
						orderId: orderId,
						transactionMethod: transactionMethod,
						amount: list.reduce((acc, curr) => acc + curr.amount, 0),
						tipAmount: tipAmount,
						orderProductDetails: list,
					},
					tableIds: tables.map((t) => t.id),
				},
			});
			if (!data) throw new Error("No data returned from server");

			await confirm({
				title: "Ok",
				content: "Orden pagada correctamente",
				type: "success",
				showCancelButton: false,
			});

			return {
				...data.createOrderPayment,
				date: new Date(data.createOrderPayment.date),
			};
		} catch (error) {
			confirm({
				title: "Ups, ocurrió un error",
				content: (error as Error).message,
				type: "error",
				showCancelButton: false,
			});
			Sentry.captureException(error);
		}
	};

	return { loading, createOrderPayment };
}
