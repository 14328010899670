import "./popover.css";

interface PopoverProps {
	children: React.ReactNode;
	control: React.ReactNode;
	id: string;
}

export function Popover({ children, id, control }: PopoverProps) {
	return (
		<>
			{control}
			<div popover="auto" id={id}>
				{children}
			</div>
		</>
	);
}
