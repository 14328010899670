import { classNames } from "@/utils/class-names";
import { useEffect } from "react";

interface ModalProps {
	children: React.ReactNode;
	show: boolean;
	onClose?: () => void;
}

export function Modal({ children, show, onClose }: ModalProps) {
	useEffect(() => {
		const handleEsc = (event: KeyboardEvent) => {
			if (event.key === "Escape") {
				onClose && onClose();
			}
		};
		document.addEventListener("keydown", handleEsc);
		return () => {
			document.removeEventListener("keydown", handleEsc);
		};
	}, [show, onClose]);

	return (
		<div
			className={classNames("modal", { show, "d-block": show })}
			style={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
		>
			<div className="modal-dialog">
				<div className="modal-content">{children}</div>
			</div>
		</div>
	);
}
