import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { CREATE_INVOICE_CANCELLATION } from "@/graphql/mutations";
import { useConfirmDialog } from "@/hooks/use-confirm-dialog";

import {
	InvoiceCancellation,
	InvoiceCancellationResponse,
} from "@/interfaces/invoice-cancellation.interface";

export function useInvoiceCancellation() {
	const { confirm } = useConfirmDialog();
	const [cancelInvoice, { loading }] = useMutation<{
		createInvoiceCancellation: InvoiceCancellationResponse;
	}>(CREATE_INVOICE_CANCELLATION);

	const createCancellation = useCallback(
		async (
			invoiceId: string,
			reason: string,
		): Promise<InvoiceCancellation | undefined> => {
			const resp = await confirm({
				title: "Anular",
				content: "¿Estás seguro de anular el documento?",
			});
			if (!resp) return;
			try {
				const { data } = await cancelInvoice({
					variables: {
						invoiceId,
						reason,
					},
				});

				if (!data?.createInvoiceCancellation)
					throw new Error(`No se pudo anular.`);

				return {
					...data.createInvoiceCancellation,
					date: new Date(data.createInvoiceCancellation.date),
				};
			} catch (error: unknown) {
				await confirm({
					title: "Error",
					content: "Ha ocurrido un error al anular el documento.",
					showCancelButton: false,
				});
			}
		},
		[cancelInvoice, confirm],
	);

	return { createCancellation, loading };
}
