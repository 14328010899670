import { useRef, useState } from "react";
import { NavLink, Link } from "react-router-dom";

import Logout from "../atoms/logout";
import { Button } from "../atoms/button/button";
import { useOutsideAlerter } from "./use-outside-alerter";
import { If } from "../atoms/if/if";
import { isProduction } from "@/utils/environment";
import { classNames } from "@/utils/class-names";

import { UserRole } from "@/interfaces/user-role.enum";

const ROUTES = [
	{
		path: "/",
		name: "Mesas",
		allow: () => true,
	},
	{
		path: "/orders",
		name: "Órdenes",
		allow: () => true,
	},
	{
		path: "/order-report",
		name: "Reporte",
		allow: (role: UserRole) => [UserRole.ADMIN, UserRole.CAJA].includes(role),
	},
	{
		path: "/invoices",
		name: "Comprobantes",
		allow: (role: UserRole) => [UserRole.ADMIN, UserRole.CAJA].includes(role),
	},
	{
		path: "/movements",
		name: "Movimientos",
		allow: (role: UserRole) => [UserRole.ADMIN, UserRole.CAJA].includes(role),
	},
];

interface NavbarProps {
	username: string;
	role: UserRole;
}

const navbarStyle = isProduction
	? "navbar-dark bg-dark"
	: "navbar-light bg-warning";
const testIcon = isProduction ? "" : "🧪";
const buttonMode = isProduction ? "dark" : "warning";

const Navbar: React.FC<NavbarProps> = ({ username, role }) => {
	const toggleRef = useRef(null);
	const dropdownRef = useRef(null);
	const [toggleOpen, setToggleOpen] = useState(false);
	const [showDropdown, setShowDropdown] = useState(false);
	useOutsideAlerter(toggleRef, () => setToggleOpen(false), {
		skip: [dropdownRef],
	});
	useOutsideAlerter(dropdownRef, () => setShowDropdown(false));

	return (
		<nav className={classNames("navbar", "navbar-expand-lg", navbarStyle)}>
			<div className="container">
				<Link className="navbar-brand" to="/">
					{testIcon} La Ladrillera
				</Link>
				<Button
					ref={toggleRef}
					onClick={() => setToggleOpen(!toggleOpen)}
					className="navbar-toggler"
					mode={buttonMode}
					aria-expanded={toggleOpen}
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon"></span>
				</Button>
				<div
					className="collapse navbar-collapse"
					style={{ display: toggleOpen ? "block" : "none" }}
				>
					<ul className="navbar-nav me-auto mb-2 mb-lg-0">
						{ROUTES.map(({ path, name, allow }) => (
							<If key={path} condition={allow(role)}>
								<li className="nav-item">
									<NavLink
										className={({ isActive }) =>
											isActive ? "nav-link active" : "nav-link"
										}
										to={path}
									>
										{name}
									</NavLink>
								</li>
							</If>
						))}
					</ul>
					<ul className="navbar-nav ml-auto">
						<li className="nav-item dropdown">
							<span
								ref={dropdownRef}
								className="nav-link dropdown-toggle"
								role="button"
								aria-expanded="false"
								style={{ cursor: "pointer" }}
								onClick={() => setShowDropdown(!showDropdown)}
							>
								<i className="fas fa-user" /> {username}
							</span>
							<div
								style={{ display: showDropdown ? "block" : "none" }}
								className="dropdown-menu dropdown-menu-right dropdown-info"
								aria-labelledby="navbarDropdown"
							>
								<Logout />
							</div>
						</li>
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
