import { useEffect, useState } from "react";

import { Order } from "../interfaces/order.interface";
import { OrderPaymentStatus } from "../interfaces/order-payment-status.enum";

const isTheSameOrder = (order: Order, originalOrder: Order): boolean => {
	const clientChanged = order.clientName !== originalOrder.clientName;
	const observationsChanged = order.observations !== originalOrder.observations;
	const totalChanged = order.total !== originalOrder.total;

	const productsChanged = order.products.some(
		(p) => !p.id || p.details.some((d) => !d.id),
	);

	if (clientChanged || observationsChanged || totalChanged || productsChanged)
		return false;

	const allDetails = originalOrder.products.map((p) => {
		const el = order.products.find((op) => op.id === p.id);

		return p.details.map((d) => {
			const eld = el?.details.find((od) => od.id === d.id) || null;

			return {
				original: d,
				current: eld,
			};
		});
	});

	const detailsChanged = allDetails.flat().some((d) => {
		const { original, current } = d;
		if (!current) return true; // product details was deleted

		const { discountRate } = current;
		const { discountRate: originalDiscountRate } = original;

		return discountRate !== originalDiscountRate;
	});

	return !detailsChanged;
};

export function useOrderIsModified(order: Order, originalOrder: Order) {
	const [wasModified, setWasModified] = useState(false);

	useEffect(() => {
		if (!order.id || order.paymentStatus === OrderPaymentStatus.PAID)
			return setWasModified(false);

		if (!isTheSameOrder(order, originalOrder)) return setWasModified(true);

		setWasModified(false);
	}, [order, originalOrder]);

	return {
		wasModified,
	};
}
