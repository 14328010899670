import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "@/store/use-auth";
import { UserRole } from "@/interfaces/user-role.enum";

export interface WithAuthenticationProps {
	name: string;
	role: UserRole;
}

function withAuthentication<
	T extends WithAuthenticationProps = WithAuthenticationProps,
>(WrappedComponent: React.ComponentType<T>) {
	return function (props: Omit<T, keyof WithAuthenticationProps>) {
		const { auth, name, role } = useAuth();
		const location = useLocation();

		return auth ? (
			<WrappedComponent {...(props as T)} name={name} role={role} />
		) : (
			<Navigate to="/login" state={{ from: location }} />
		);
	};
}

export default withAuthentication;
