import { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { authService } from "../services/firebase";
import { useAuth } from "../store/use-auth";
import { useAuthMutations } from "../store/use-auth-mutations";
import Loading from "../components/atoms/loading";
import LoginTemplate from "../components/templates/login/login";
import { UserRole } from "@/interfaces/user-role.enum";

const LoginPage: React.FC = () => {
	const [errorMessage, setErrorMessage] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const { auth } = useAuth();
	const { login } = useAuthMutations();
	const location = useLocation();

	useEffect(() => {
		const unsub = authService.onAuthStateChanged(async (user) => {
			if (user) {
				const { claims } = await user.getIdTokenResult();
				const { roles, user_id, name, email } = claims as {
					roles: UserRole[];
					user_id: string;
					name: string;
					email: string;
				};
				const token = await user.getIdToken(true);
				Sentry.setUser({ email, id: user_id, username: name });

				login({ id: user_id, name, role: roles[0], token });
			} else {
				setIsLoading(false);
				Sentry.setUser(null);
			}
		});

		return () => {
			unsub();
		};
	}, [login]);

	if (auth) {
		const { pathname, search } = location.state?.from || {
			pathname: "/login",
			search: "",
		};
		return (
			<Navigate
				to={{ pathname: pathname === "/login" ? "/" : pathname, search }}
			/>
		);
	}

	const handleClick = (email: string, password: string) => {
		setIsLoading(true);
		authService.signInWithEmailAndPassword(email, password).catch((e) => {
			setErrorMessage(e.message);
			setIsLoading(false);
		});
	};

	return isLoading ? (
		<Loading />
	) : (
		<LoginTemplate handleClick={handleClick} message={errorMessage} />
	);
};

export default LoginPage;
