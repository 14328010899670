import { useEffect } from "react";

export function useOutsideAlerter(
	ref: React.RefObject<HTMLElement>,
	callback: () => void,
	options: { skip: React.RefObject<HTMLElement>[] } = { skip: [] },
) {
	useEffect(() => {
		let timeoutId: number;
		const skippedNodes = options.skip || [];

		function handleClickOutside(event: MouseEvent) {
			const node = event.target as Node;

			const isNodeSkiped = skippedNodes.find(
				(el) => el.current?.contains(node),
			);
			if (isNodeSkiped) return;

			if (ref.current && !ref.current.contains(node)) {
				timeoutId = window.setTimeout(callback, 50);
			}
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			window.clearTimeout(timeoutId);
		};
	}, [ref, callback, options.skip]);
}
