import { Button } from "@/components/atoms/button/button";
import { If } from "@/components/atoms/if/if";

import { OrderPaymentStatus } from "@/interfaces/order-payment-status.enum";
import { OrderForm } from "@/interfaces/order-payment.interfaces";
import { OrderStatus } from "@/interfaces/order-status.enum";

interface OrderControlsProps {
	order: OrderForm;
	wasModified: boolean;
	paymentMode: boolean;
	handleCreateOrder: () => void;
	handleReleaseOrder: () => void;
	onPaymentModeClick: () => void;
}

export function OrderControls({
	order,
	wasModified,
	paymentMode,
	handleCreateOrder,
	handleReleaseOrder,
	onPaymentModeClick,
}: OrderControlsProps) {
	const isAtended = order.status === OrderStatus.ATENDED;
	const isNotAnulated = order.status !== OrderStatus.ANULATED;
	const isNotPaid = order.paymentStatus !== OrderPaymentStatus.PAID;
	const isCreated = order.status !== OrderStatus.UNREGISTERED;

	return (
		<If condition={!paymentMode && isNotAnulated}>
			<div className="d-flex justify-content-end mt-2 gap-2">
				<If condition={isNotPaid}>
					<Button
						mode="success"
						disabled={isCreated && !wasModified}
						onClick={() => handleCreateOrder()}
					>
						{isCreated ? "Actualizar" : "Registrar"}
					</Button>
				</If>
				<If condition={isCreated && !isAtended && isNotPaid}>
					<Button
						mode="secondary"
						onClick={() => handleReleaseOrder()}
						disabled={wasModified}
					>
						Liberar
					</Button>
				</If>
				<If condition={isCreated && isNotPaid}>
					<Button
						mode="secondary"
						onClick={onPaymentModeClick}
						disabled={wasModified}
					>
						Pagar
					</Button>
				</If>
			</div>
		</If>
	);
}
