import { gql } from "@apollo/client";

export const ORDER_CHANGE = gql`
	subscription {
		orderAdded {
			id
			number
			order {
				id
				status
				type
			}
		}
	}
`;
