import { Suspense, lazy, useState } from "react";
import Loading from "@/components/atoms/loading";
import { Order } from "@/interfaces/order.interface";
import { formatDate } from "@/utils/date";
import { currencyFormat } from "@/utils/currency-format";

const Ticket = lazy(() => import("@/components/organisms/ticket"));

interface OrderDetailProps {
	order: Order;
}

export function OrderDetail({ order }: OrderDetailProps) {
	const [showTicket, setShowTicket] = useState(false);

	return (
		<Suspense fallback={<Loading />}>
			{showTicket && (
				<Ticket order={order} onClose={() => setShowTicket(false)} />
			)}
			<div className="row mt-2">
				<div className="col-md-3 col-6">
					<p className="m-0">
						<span className="me-1">#️⃣</span>
						{order.number}
					</p>
				</div>
				<div className="col-md-3 col-6">
					<p className="m-0">
						<span className="me-1">📅</span>
						{formatDate({ date: order.date })}
					</p>
				</div>
				<div className="col-md-3 col-6">
					<p className="m-0">
						<span className="me-1">💴</span>
						{currencyFormat(order.total)}
					</p>
				</div>
				<div className="col-md-3 col-6">
					{order.id && (
						<p className="m-0">
							<span
								className="me-2"
								onClick={() => setShowTicket(true)}
								style={{ cursor: "pointer" }}
							>
								🖨️
							</span>
						</p>
					)}
				</div>
			</div>
		</Suspense>
	);
}
