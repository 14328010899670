import { CheckboxStates } from "@/interfaces/checkbox-statuses.enum";
import { OrderProductForm } from "@/interfaces/order-payment.interfaces";

export function calculateSelectAllCheckbox(
	products: OrderProductForm[],
): CheckboxStates {
	const { Checked, Empty, Indeterminate } = CheckboxStates;
	const filteredProducts = products.filter((p) => p.paidUnits !== p.quantity);

	if (
		filteredProducts.some((p) => p.checkboxState === Indeterminate) ||
		(filteredProducts.some((p) => p.checkboxState === Empty) &&
			filteredProducts.some((p) => p.checkboxState === Checked))
	) {
		return Indeterminate;
	}

	if (filteredProducts.every((p) => p.checkboxState === Checked)) {
		return Checked;
	}

	return Empty;
}
