import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Layout from "../layout";
import { If } from "@/components/atoms/if/if";
import { Loading } from "@/components/atoms/loading";
import { isUUID } from "@/utils/uuid";
import { ORDER_PAYMENT_IDS } from "@/constants/search-params";
import {
	GET_INVOICE_BY_ID,
	GET_ORDER_PAYMENTS_BY_IDS,
} from "@/graphql/queries";
import { Button } from "@/components/atoms/button/button";
import { InvoiceCustomer } from "./invoice-customer";
import { WithAuthenticationProps } from "@/hoc/withAuthentication";
import { invoiceResponseToInvoice } from "./invoice-response-to-invoice";
import { InvoiceInfo } from "./invoice-info";
import { useCreateInvoice } from "./use-create-invoice";
import { InvoiceOrderPayments } from "./invoice-order-payments";
import { reduceOrderPayments } from "./reduce-order-payments";
import { InvoiceDetails } from "./invoice-details";
import { divideAmount } from "@/utils/divide-amount";
import { roundToDecimals } from "@/utils/round-to-decimals";

import {
	OrderPayment,
	OrderPaymentResponse,
} from "@/interfaces/order-payment.interfaces";
import { UserRole } from "@/interfaces/user-role.enum";
import { Customer } from "@/interfaces/customer";
import { InvoiceDetail } from "@/interfaces/invoice-detail";
import { Invoice, InvoiceResponse, InvoiceType } from "@/interfaces/invoice";
import { Service } from "@/interfaces/service";

interface InvoicePageProps extends WithAuthenticationProps {}

export function InvoicePage({ role }: InvoicePageProps) {
	const { id } = useParams();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const orderPaymentIds = searchParams.getAll(ORDER_PAYMENT_IDS).filter(isUUID);
	const [services, setServices] = useState<Service[]>([]);
	const [invoice, setInvoice] = useState<Invoice | undefined>(undefined);
	const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetail[]>([]);
	const [dbInvoiceDetails, setDbInvoiceDetails] = useState<InvoiceDetail[]>([]);
	const [editEnabled, setEditEnabled] = useState(false);
	const [orderPayments, setOrderPayments] = useState<OrderPayment[]>([]);
	const { createInvoice, loading: l1 } = useCreateInvoice();

	const { loading } = useQuery<{
		orderPaymentsByIds: OrderPaymentResponse[];
	}>(GET_ORDER_PAYMENTS_BY_IDS, {
		variables: { orderPaymentIds: orderPaymentIds },
		skip: orderPaymentIds.length === 0 || id !== undefined,
		fetchPolicy: "no-cache",
		onCompleted: (data) => {
			setOrderPayments(
				data.orderPaymentsByIds.map((op) => ({
					...op,
					date: new Date(op.date),
				})),
			);
			const reduced = reduceOrderPayments(data.orderPaymentsByIds);
			setDbInvoiceDetails(reduced);
			setInvoiceDetails(reduced);
		},
	});

	const { loading: l2 } = useQuery(GET_INVOICE_BY_ID, {
		variables: { invoiceId: id },
		skip: id === undefined,
		fetchPolicy: "no-cache",
		onCompleted: (data: { getInvoiceById: InvoiceResponse }) => {
			const invoice = invoiceResponseToInvoice(data.getInvoiceById);
			setOrderPayments(invoice.orderPayments);
			setInvoice(invoice);
			setInvoiceDetails(invoice.details);
		},
	});

	useEffect(() => {
		const hasServices = services.length > 0;
		setEditEnabled(false);
		if (hasServices) {
			const total = dbInvoiceDetails.reduce((d, curr) => curr.total + d, 0);
			const result = divideAmount(total, services.length);
			setInvoiceDetails(
				services.map((s, i) => ({
					code: s.code,
					name: s.name,
					units: 1,
					unitPrice: result[i],
					total: result[i],
					discount: 0,
					measurementUnit: s.measurementUnit,
				})),
			);
		} else if (invoice !== undefined) {
			setInvoiceDetails(invoice.details);
		} else {
			setInvoiceDetails(dbInvoiceDetails);
		}
	}, [services, dbInvoiceDetails, invoice]);

	const handleCreateInvoice = async (
		customer: Customer,
		invoiceType: InvoiceType,
	) => {
		const isCustom = services.length > 0 || editEnabled;
		const id = await createInvoice(
			invoiceType,
			customer,
			orderPaymentIds,
			invoiceDetails,
			isCustom,
		);

		if (id) {
			setServices([]);
			navigate(`/invoice/${id}`);
		}
	};

	const updateInvoiceDetails = (detail: InvoiceDetail) => {
		const newDetails = invoiceDetails.map((d) => {
			if (d.code === detail.code) {
				return { ...detail };
			}
			return { ...d };
		});

		setInvoiceDetails(newDetails);
	};

	const removeDiscount = () => {
		const newDetails = invoiceDetails.map((d) => {
			return { ...d, discount: 0, total: d.total + d.discount };
		});

		setInvoiceDetails(newDetails);
	};

	const total = roundToDecimals(
		invoice
			? invoice.orderPayments.reduce((d, curr) => curr.amount + d, 0)
			: dbInvoiceDetails.reduce((d, curr) => curr.total + d, 0),
		2,
	);
	return (
		<Layout>
			<If condition={loading || l1 || l2} children={<Loading />} />
			<div className="d-flex justify-content-between align-items-center mt-2">
				<h2 className="m-0">Comprobante</h2>
				<div className="d-flex gap-2">
					<Button onClick={() => navigate(-1)} mode="danger" size="small">
						Regresar
					</Button>
				</div>
			</div>
			<If condition={[UserRole.ADMIN, UserRole.CAJA].includes(role) && !id}>
				<InvoiceCustomer
					services={services}
					onChangeService={setServices}
					handleCreateInvoice={handleCreateInvoice}
				/>
			</If>
			<If condition={!!invoice}>
				<InvoiceInfo invoice={invoice!} setInvoice={setInvoice} />
			</If>
			<InvoiceDetails
				isRegistered={!!invoice}
				invoiceDetails={invoiceDetails}
				total={total}
				editEnabled={editEnabled}
				updateInvoiceDetails={updateInvoiceDetails}
				changeEditEnabled={(checked) => {
					if (!checked) setServices([]);
					if (checked) removeDiscount();
					setEditEnabled(checked);
				}}
			/>
			<InvoiceOrderPayments orderPayments={orderPayments} />
		</Layout>
	);
}
