import { calculateSelectAllCheckbox } from "@/pages/payment/calculate-select-all-checkbox";
import { orderToOrderForm } from "@/pages/payment/order-to-order-form";
import { updatePaymentOrderProductByChecked } from "@/pages/payment/update-payment-order-product-by-checked";

import { OrderForm } from "@/interfaces/order-payment.interfaces";
import { OrderPaymentStatus } from "@/interfaces/order-payment-status.enum";
import { OrderStatus } from "@/interfaces/order-status.enum";
import { OrderType } from "@/interfaces/order-type.enum";
import { Order } from "@/interfaces/order.interface";
import { CheckboxStates } from "@/interfaces/checkbox-statuses.enum";

export const INITIAL_STATE: Order = {
	id: null,
	number: null,
	products: [],
	date: new Date(),
	total: 0,
	observations: null,
	clientName: null,
	tables: [],
	status: OrderStatus.UNREGISTERED,
	type: OrderType.PICKUP,
	paymentStatus: OrderPaymentStatus.PENDING,
	tableNumber: "llevar",
	payments: [],
};

export enum OrderReducerType {
	SET = "set",
	ADD = "add",
	REMOVE = "remove",
	UPDATE_DISCOUNT = "updateDiscount",
	OBSERVATIONS = "observations",
	CLIENT_NAME = "clientName",
	RESET = "reset",
	SELECT_ALL_FOR_PAYMENT = "selectAllForPayment",
	SELECT_ONE_FOR_PAYMENT = "selectOneForPayment",
	PRODUCT_CHANGE_FOR_PAYMENT = "productChangeForPayment",
}

export interface OrderReducerAction {
	type: OrderReducerType;
	payload: Partial<OrderForm>;
}

export function orderReducer(
	state: OrderForm,
	action: OrderReducerAction,
): OrderForm {
	const { type, payload } = action;

	switch (type) {
		case OrderReducerType.SET:
			return {
				...state,
				...payload,
			};
		case OrderReducerType.ADD: {
			if (payload.products === undefined)
				throw new Error("Products is undefined");

			const products = [...state.products];
			const { product } = payload.products[0];
			const { id } = product;

			const index = products.findIndex((d) => d.product.id === id);
			if (index >= 0) {
				products[index] = payload.products[0];
			} else {
				products.push(payload.products[0]);
			}
			const total = products.reduce((a, b) => a + b.productSubTotal, 0);
			return { ...state, total, products };
		}
		case OrderReducerType.REMOVE: {
			if (payload.products === undefined)
				throw new Error("Products is undefined");

			const products = [...state.products];
			const { product, quantity } = payload.products[0];
			const { id } = product;

			const index = products.findIndex((d) => d.product.id === id);
			if (quantity === 0) {
				products.splice(index, 1);
			} else {
				products[index] = payload.products[0];
			}

			const total = products.reduce((a, b) => a + b.productSubTotal, 0);

			return { ...state, total, products };
		}
		case OrderReducerType.UPDATE_DISCOUNT: {
			if (payload.products === undefined)
				throw new Error("Products is undefined");

			const products = [...state.products];
			const { product } = payload.products[0];
			const { id } = product;

			const index = products.findIndex((d) => d.product.id === id);
			products[index] = payload.products[0];

			const total = products.reduce((a, b) => a + b.productSubTotal, 0);

			return { ...state, total, products };
		}
		case OrderReducerType.OBSERVATIONS: {
			const { observations } = payload;
			if (observations === undefined)
				throw new Error("Observations is undefined");

			return {
				...state,
				observations: observations === "" ? null : observations,
			};
		}
		case OrderReducerType.CLIENT_NAME: {
			const { clientName } = payload;
			if (clientName === undefined) throw new Error("ClientName is undefined");

			return {
				...state,
				clientName: clientName === "" ? null : clientName,
			};
		}
		case OrderReducerType.SELECT_ALL_FOR_PAYMENT: {
			const { products } = state;
			const { checkboxState = CheckboxStates.Checked } = payload;
			const checked = checkboxState === CheckboxStates.Checked;

			const selectedAll = products.map((product) => {
				return updatePaymentOrderProductByChecked(product, checked);
			});

			return {
				...state,
				checkboxState,
				products: selectedAll,
			};
		}
		case OrderReducerType.SELECT_ONE_FOR_PAYMENT: {
			const { products } = state;
			const { products: orderProducts } = payload;
			const { id: orderProductId, checkboxState } = orderProducts![0];
			const checked = checkboxState === CheckboxStates.Checked;

			const result = products.map((product) => {
				if (product.id === orderProductId) {
					return updatePaymentOrderProductByChecked(product, checked);
				}
				return product;
			});

			return {
				...state,
				checkboxState: calculateSelectAllCheckbox(result),
				products: result,
			};
		}
		case OrderReducerType.PRODUCT_CHANGE_FOR_PAYMENT: {
			const { products } = state;
			const { products: orderProducts } = payload;
			const orderProduct = orderProducts![0];

			const result = products.map((product) => {
				if (product.id === orderProduct.id) return orderProduct;
				return product;
			});

			return {
				...state,
				checkboxState: calculateSelectAllCheckbox(result),
				products: result,
			};
		}
		case OrderReducerType.RESET:
			return orderToOrderForm(INITIAL_STATE);
		default:
			throw new Error("Action type not found");
	}
}
