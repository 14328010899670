import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

import { Button } from "@/components/atoms/button/button";

import classes from "./confirm-dialog.module.css";

interface ConfirmModalProps {
	onConfirm: () => void;
	onCancel: () => void;
	open: boolean;
	showCancelButton?: boolean;
	title?: string;
	content?: string;
	confirmLabel?: string;
	cancelLabel?: string;
	type?: keyof typeof confirmDialogType;
}

const confirmDialogType = {
	question: "🤨",
	error: "😡",
	warning: "😱",
	success: "😎",
};

export const ConfirmDialog = ({
	onConfirm,
	onCancel,
	open,
	title = "Confirmación",
	content = "¿Estás seguro de realizar esta acción?",
	confirmLabel = "Confirmar",
	cancelLabel = "Cancelar",
	type = "question",
	showCancelButton = true,
}: ConfirmModalProps) => {
	const dialog = useRef<HTMLDialogElement>(null);

	useEffect(() => {
		if (open) {
			dialog.current?.showModal();
		} else {
			dialog.current?.close();
		}
	}, [open]);

	return createPortal(
		<dialog ref={dialog} onCancel={onCancel} className={classes.dialog}>
			<section>
				<p className={classes.icon}>{confirmDialogType[type]}</p>
				<h3 className={classes.title}>{title}</h3>
				<p className={classes.content}>{content}</p>
			</section>
			<menu className={classes.menu}>
				{showCancelButton && <Button onClick={onCancel}>{cancelLabel}</Button>}
				<Button mode="success" onClick={onConfirm}>
					{confirmLabel}
				</Button>
			</menu>
		</dialog>,
		document.getElementById("confirm-dialog")!,
	);
};
