import { useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";

import { Button } from "@/components/atoms/button/button";
import { Input } from "@/components/atoms/input/input";
import { Loading } from "@/components/atoms/loading";
import { If } from "@/components/atoms/if/if";
import { useCreateCustomer } from "./use-create-customer";
import { useSearchCustomer } from "./use-search-customer";
import { useUpdateCustomer } from "./use-update-customer";
import { DOCUMENT_TYPES } from "@/constants/document-types";

import {
	Customer,
	CustomerForm as CustomerFormType,
} from "@/interfaces/customer";
import { DocumentType } from "@/interfaces/document-type.enum";

interface CustomerFormProps {
	customer?: Customer;
	isInvoiceCustomer?: boolean;
	onCancelModal: () => void;
	onCustomerCreated: (customer: Customer) => void;
}

const customerInitial: CustomerFormType = {
	id: undefined,
	documentType: "",
	documentNumber: "",
	fullName: "",
	address: "",
	email: "",
	phoneNumber: "",
	isSaved: false,
};

export function CustomerForm({
	onCancelModal,
	onCustomerCreated,
	customer: c,
	isInvoiceCustomer,
}: CustomerFormProps) {
	const { searchCustomer, loading: l1 } = useSearchCustomer();
	const { createCustomer, loading: l2 } = useCreateCustomer();
	const { updateCustomer, loading: l3 } = useUpdateCustomer();
	const [documentTypes, setDocumentTypes] = useState(DOCUMENT_TYPES);
	const [customer, setCustomer] = useState<CustomerFormType>(customerInitial);
	const [disableModify, setDisableModify] = useState<boolean>(false);
	const [isModified, setIsModified] = useState<boolean>(false);
	const apolloClient = useApolloClient();

	useEffect(() => {
		if (c) {
			setCustomer({
				...c,
				documentType: c.documentType,
				documentNumber: c.documentNumber,
				fullName: c.fullName,
				address: c.address ?? "",
				email: c.email ?? "",
				phoneNumber: c.phoneNumber ?? "",
				isSaved: true,
			});
			setDisableModify(true);
		} else {
			setCustomer(customerInitial);
			setDisableModify(false);
		}
	}, [c]);

	useEffect(() => {
		if (c) {
			if (c.fullName !== customer.fullName) return setIsModified(true);

			const address = c.address ?? "";
			if (address !== customer.address) return setIsModified(true);

			const email = c.email ?? "";
			if (email !== customer.email) return setIsModified(true);

			const phoneNumber = c.phoneNumber ?? "";
			if (phoneNumber !== customer.phoneNumber) return setIsModified(true);

			setIsModified(false);
		} else {
			setIsModified(false);
		}
	}, [customer, c]);

	useEffect(() => {
		if (isInvoiceCustomer) {
			setDocumentTypes([DOCUMENT_TYPES[0]]);
			setCustomer((prev) => ({
				...prev,
				documentType: DocumentType.RUC,
			}));
			return;
		}

		setDocumentTypes(DOCUMENT_TYPES);
		setCustomer((prev) => ({
			...prev,
			documentType: DocumentType.DNI,
		}));
	}, [isInvoiceCustomer]);

	const handleCustomerSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (customer.documentType === "") return;

		const c = customer.isSaved
			? await updateCustomer(customer)
			: await createCustomer(customer);
		if (!c) return;

		apolloClient.cache.evict({
			id: "ROOT_QUERY",
			fieldName: "findCustomers",
		});
		apolloClient.cache.gc();
		resetForm();
		onCustomerCreated({
			...c,
			displayName: `${c.documentNumber} - ${c.fullName}`,
		});
	};

	const resetForm = () => {
		setCustomer(customerInitial);
		setDisableModify(false);
	};

	const handleChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
	) => {
		const { name, value } = e.target;
		setCustomer((prev) => ({ ...prev, [name]: value }));
	};

	return (
		<div className="modal-body">
			<If condition={l1 || l2 || l3} children={<Loading />} />
			<form className="row g-3" onSubmit={handleCustomerSubmit}>
				<div className="col-md-12">
					<label htmlFor="document-type" className="form-label">
						Documento
					</label>
					<div className="input-group">
						<select
							value={customer.documentType}
							disabled={disableModify}
							name="documentType"
							onChange={handleChange}
							required
							id="document-type"
							className="form-select"
						>
							<option value="">Elegir...</option>
							{documentTypes.map(({ value, label }) => (
								<option key={value} value={value}>
									{label}
								</option>
							))}
						</select>
						<Input
							name="documentNumber"
							value={customer.documentNumber}
							onChange={handleChange}
							disabled={disableModify}
							placeholder="Número documento"
							required
						/>
						<If
							condition={
								(DocumentType.RUC === customer.documentType &&
									customer.documentNumber.length === 11) ||
								(DocumentType.DNI === customer.documentType &&
									customer.documentNumber.length === 8)
							}
						>
							<Button
								mode="secondary"
								onClick={async () => {
									if (customer.documentType === "") return;

									const customerResponse = await searchCustomer(
										customer.documentNumber,
										customer.documentType,
									);

									setDisableModify(true);
									if (!customerResponse) return;

									setCustomer({
										...customer,
										fullName: customerResponse.fullName,
										address: customerResponse.address ?? customer.address,
									});
								}}
							>
								{customer.isSaved ? "Sincronizar" : "Buscar"}
							</Button>
						</If>
					</div>
				</div>
				<div className="col-md-12">
					<Input
						name="fullName"
						value={customer.fullName}
						onChange={handleChange}
						label="Nombre"
						required
					/>
				</div>
				<div className="col-12">
					<Input
						name="address"
						value={customer.address}
						onChange={handleChange}
						label="Dirección"
						required={
							customer.documentType === DocumentType.RUC &&
							customer.documentNumber.startsWith("20")
						}
					/>
				</div>
				<div className="col-md-6">
					<Input
						name="email"
						value={customer.email}
						onChange={handleChange}
						label="Email"
						type="email"
					/>
				</div>
				<div className="col-md-6">
					<Input
						name="phoneNumber"
						value={customer.phoneNumber}
						onChange={handleChange}
						label="Teléfono"
						type="tel"
					/>
				</div>
				<div className="col-12 text-end">
					<Button
						className="me-2"
						mode="secondary"
						onClick={() => {
							resetForm();
							onCancelModal();
						}}
					>
						Cancelar
					</Button>
					<Button type="submit" disabled={customer.isSaved && !isModified}>
						{customer.isSaved ? "Actualizar" : "Guardar"}
					</Button>
				</div>
			</form>
		</div>
	);
}
