import { useRef } from "react";
import { ConfirmDialogContext, ConfirmDialogWithContext } from ".";

export function ConfirmDialogProvider({
	children,
}: {
	children: React.ReactNode;
}) {
	const confirmRef = useRef(() => Promise.resolve(true));

	return (
		<ConfirmDialogContext.Provider value={{ confirmRef }}>
			<ConfirmDialogWithContext />
			{children}
		</ConfirmDialogContext.Provider>
	);
}
