import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { useConfirmDialog } from "@/hooks/use-confirm-dialog";
import { UPDATE_CUSTOMER } from "@/graphql/mutations";

import { Customer, CustomerForm } from "@/interfaces/customer";

export function useUpdateCustomer() {
	const [update, { loading }] = useMutation<{
		updateCustomer: Customer;
	}>(UPDATE_CUSTOMER);
	const { confirm } = useConfirmDialog();

	const updateCustomer = useCallback(
		async (customer: CustomerForm) => {
			try {
				const {
					id,
					fullName,
					documentType,
					documentNumber,
					address,
					phoneNumber,
					email,
				} = customer;

				const { data } = await update({
					variables: {
						customer: {
							id,
							fullName: fullName.trim(),
							documentType: documentType,
							documentNumber: documentNumber,
							address: address === "" ? undefined : address?.trim(),
							phoneNumber: phoneNumber === "" ? undefined : phoneNumber,
							email: email === "" ? undefined : email,
						},
					},
					fetchPolicy: "no-cache",
				});

				if (!data?.updateCustomer)
					throw new Error("No se pudo actualizar el cliente.");

				return data.updateCustomer;
			} catch (error: unknown) {
				await confirm({
					type: "error",
					content: (error as Error).message,
					showCancelButton: false,
					title:
						"No pudimos actualizar el cliente. Por favor, intenta nuevamente.",
					confirmLabel: "Aceptar",
				});
			}
		},
		[update, confirm],
	);

	return { updateCustomer, loading };
}
