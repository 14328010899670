import { useCallback } from "react";
import { useMutation } from "@apollo/client";

import { CANCEL_MONEY_MOVEMENT } from "@/graphql/mutations";
import { useConfirmDialog } from "@/hooks/use-confirm-dialog";

interface CancelMoneyMovement {
	cancelMoneyMovement: boolean;
}

export function useCancelMoneyMovement() {
	const { confirm } = useConfirmDialog();
	const [cancel, { loading }] = useMutation<CancelMoneyMovement>(
		CANCEL_MONEY_MOVEMENT,
	);

	const cancelMoneyMovement = useCallback(
		async (movementId: string) => {
			const resp = await confirm({
				title: "Confirmación",
				content: `¿Estás seguro de cancelar el movimiento?`,
			});

			if (!resp) return undefined;

			try {
				const response = await cancel({
					variables: {
						moneyMovementId: movementId,
					},
				});

				return response.data?.cancelMoneyMovement;
			} catch (error: unknown) {
				await confirm({
					type: "error",
					title: "Error",
					content: `Error. ${(error as Error).message}`,
					showCancelButton: false,
				});
			}
		},
		[cancel, confirm],
	);

	return { cancelMoneyMovement, loading };
}
