import { useState } from "react";

import { Button } from "@/components/atoms/button/button";
import { currencyFormat } from "@/utils/currency-format";
import { TRANSACTION_METHODS } from "@/constants/transaction-method";
import { Input } from "@/components/atoms/input/input";

import { OrderProductForm } from "@/interfaces/order-payment.interfaces";
import { TransactionMethod } from "@/interfaces/transaction-methos.enum";

interface OrderPaymentSummaryProps {
	orderProducts: OrderProductForm[];
	orderTotal: number;
	onPayOrder: (transactionMethod: TransactionMethod, tipAmount: number) => void;
	onCancelButtonClick: () => void;
}

export function OrderPaymentSummary(props: OrderPaymentSummaryProps) {
	const { orderProducts, orderTotal } = props;
	const [transactionMethod, setTransactionMethod] = useState<TransactionMethod>(
		TransactionMethod.CASH,
	);
	const [tipAmount, setTipAmount] = useState(0);

	return (
		<div className="card">
			<div className="card-body d-flex flex-column">
				<div className="mb-3">
					<Input
						value={tipAmount.toString()}
						type="number"
						onChange={(e) => setTipAmount(Number(e.target.value))}
						min={0}
						label="Propina"
					/>
				</div>
				<div className="mb-3">
					<label htmlFor="transaction-method" className="form-label">
						Método de pago
					</label>
					<select
						id="transaction-method"
						className="form-select"
						value={transactionMethod}
						onChange={(e) =>
							setTransactionMethod(e.target.value as TransactionMethod)
						}
						aria-label="Default select example"
					>
						{Object.entries(TransactionMethod).map(([key]) => (
							<option key={key} value={key}>
								{TRANSACTION_METHODS[key as TransactionMethod]}
							</option>
						))}
					</select>
				</div>
				<div className="mt-1">
					<p className="d-flex justify-content-between mb-0">
						<span>Total pagado:</span>
						<span>
							{currencyFormat(
								orderProducts.reduce((acc, curr) => curr.paidAmount + acc, 0),
							)}
						</span>
					</p>
					<p className="d-flex justify-content-between mb-0">
						<span>Monto pendiente:</span>
						<span>
							{currencyFormat(
								orderTotal -
									orderProducts.reduce((acc, curr) => curr.paidAmount + acc, 0),
							)}
						</span>
					</p>
					<p className="d-flex justify-content-between my-3">
						<span>Monto a pagar:</span>
						{currencyFormat(
							orderProducts.reduce((acc, curr) => curr.amountToPay + acc, 0) +
								tipAmount,
						)}
					</p>
					<div className="d-flex mt-2 justify-content-end gap-1">
						<Button
							mode="primary"
							size="small"
							onClick={() => props.onCancelButtonClick()}
						>
							Cancelar
						</Button>
						<Button
							mode="success"
							size="small"
							disabled={orderProducts.every((p) => p.unitsToPay === 0)}
							onClick={() => props.onPayOrder(transactionMethod, tipAmount)}
						>
							Confirmar pago
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}
