import { useEffect, useRef } from "react";

import { classNames } from "@/utils/class-names";
import { Input } from "@/components/atoms/input/input";

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	undeterminated?: boolean;
}

export function Checkbox({
	checked = false,
	undeterminated = false,
	onClick,
	className = undefined,
	label,
	...rest
}: CheckboxProps) {
	const checkboxRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (checkboxRef.current) {
			checkboxRef.current.indeterminate = undeterminated;
		}
	}, [undeterminated]);

	return (
		<Input
			type="checkbox"
			className={classNames("form-check-input", className)}
			labelClassName="form-check-label"
			ref={checkboxRef}
			onClick={(e) => onClick && onClick(e)}
			label={label}
			checked={undeterminated ? false : checked}
			{...rest}
		/>
	);
}
