import { OrderResponse } from "@/interfaces/order-response.interface";
import { OrderType } from "@/interfaces/order-type.enum";
import { Order } from "@/interfaces/order.interface";
import { Table } from "@/interfaces/table.interface";

export function orderResponseToOrder(response: OrderResponse): Order {
	const { tables, type } = response;
	const tableNumber = getOrderTableNumber(type, tables);

	const payments = response.payments?.map((payment) => ({
		...payment,
		date: new Date(payment.date),
	}));

	return {
		...response,
		tableNumber,
		date: new Date(response.date),
		payments: payments ?? [],
	};
}

export function getOrderTableNumber(
	orderType: OrderType,
	tables: Table[] = [],
): string {
	const isPickup = orderType === OrderType.PICKUP;

	return isPickup ? "llevar" : tables[0].number;
}
