import { TRANSACTION_METHODS } from "@/constants/transaction-method";
import { currencyFormat } from "@/utils/currency-format";
import { formatDate } from "@/utils/date";

import { OrderPayment } from "@/interfaces/order-payment.interfaces";

interface InvoiceOrderPaymentsProps {
	orderPayments: OrderPayment[];
}

export function InvoiceOrderPayments({
	orderPayments,
}: InvoiceOrderPaymentsProps) {
	return (
		<section>
			<h3>Pagos</h3>
			<div className="table-responsive">
				<table className="table table-sm">
					<thead>
						<tr>
							<th>#</th>
							<th>Fecha</th>
							<th>Monto</th>
							<th>Medio Pago</th>
							<th>Estado</th>
						</tr>
					</thead>
					<tbody>
						{orderPayments.map((op, index) => (
							<tr key={op.id}>
								<td>{index + 1}</td>
								<td>{formatDate({ date: op.date })}</td>
								<td>{currencyFormat(op.amount)}</td>
								<td>{TRANSACTION_METHODS[op.transactionMethod]}</td>
								<td>{op.status}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</section>
	);
}
