import { Input } from "@/components/atoms/input/input";
import { classNames } from "@/utils/class-names";

interface RadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	asButton?: boolean;
	buttonClassName?: string;
}

export function Radio({
	checked = false,
	onClick,
	className = undefined,
	label,
	asButton = false,
	buttonClassName,
	...rest
}: RadioProps) {
	return (
		<Input
			type="radio"
			className={classNames(
				{ "btn-check": asButton, "form-check-input": !asButton },
				className,
			)}
			labelClassName={classNames(
				{ btn: asButton, "form-check-label": !asButton },
				buttonClassName,
			)}
			onClick={(e) => onClick && onClick(e)}
			label={label}
			labelPlacement={asButton ? "bottom" : "top"}
			checked={checked}
			{...rest}
		/>
	);
}
