import { useCallback } from "react";
import { useLazyQuery } from "@apollo/client";

import { SEARCH_CUSTOMER_INFO } from "@/graphql/queries";
import { useConfirmDialog } from "@/hooks/use-confirm-dialog";

import { Customer } from "@/interfaces/customer";
import { DocumentType } from "@/interfaces/document-type.enum";

export function useSearchCustomer() {
	const [search, { loading }] = useLazyQuery<{
		searchCustomerInfo: Customer;
	}>(SEARCH_CUSTOMER_INFO);
	const { confirm } = useConfirmDialog();

	const searchCustomer = useCallback(
		async (documentNumber: string, documentType: DocumentType) => {
			try {
				const { data, error } = await search({
					variables: {
						documentNumber,
						documentType,
					},
					fetchPolicy: "no-cache",
				});

				if (error) {
					throw new Error(error.message);
				}

				if (!data?.searchCustomerInfo)
					throw new Error("Cliente no encontrado.");

				return data.searchCustomerInfo;
			} catch (error: unknown) {
				await confirm({
					type: "error",
					title: "Error",
					content: (error as Error).message,
					showCancelButton: false,
					confirmLabel: "Aceptar",
				});
			}
		},
		[search, confirm],
	);

	return { searchCustomer, loading };
}
