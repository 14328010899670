import { useContext, useCallback } from "react";
import {
	ConfirmDialogContext,
	ConfirmDialogProps,
} from "../components/organisms/confirm-dialog/confirm-dialog-context";

export function useConfirmDialog() {
	const { confirmRef } = useContext(ConfirmDialogContext);

	return {
		confirm: useCallback(
			(p: ConfirmDialogProps) => confirmRef?.current(p),
			[confirmRef],
		),
	};
}
