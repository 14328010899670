export const SERVICES = [
	{
		code: "S0001",
		name: "POR CONSUMO",
		measurementUnit: "ZZ",
	},
	{
		code: "S0002",
		name: "DESAYUNO",
		measurementUnit: "ZZ",
	},
	{
		code: "S0003",
		name: "ALMUERZO",
		measurementUnit: "ZZ",
	},
	{
		code: "S0004",
		name: "CENA",
		measurementUnit: "ZZ",
	},
];
