import { useState, useEffect } from "react";

import ButtonGroup from "./button-group";
import { Button } from "../atoms/button/button";
import { currencyFormat } from "@/utils/currency-format";
import { OrderReducerAction, OrderReducerType } from "@/utils/order-reducer";

import { Product, ProductCategory } from "@/interfaces/order.interface";
import {
	OrderProductDetailForm,
	OrderProductForm,
} from "@/interfaces/order-payment.interfaces";
import { CheckboxStates } from "@/interfaces/checkbox-statuses.enum";

const productDetails: OrderProductDetailForm = {
	id: null,
	discount: 0,
	discountRate: 0.0,
	delivered: false,
	options: [],
	registrationDate: new Date(),
	amountToPay: 0,
	paidAmount: 0,
	paidUnits: 0,
	unitsToPay: 0,
};

interface ProductListProps {
	categories: ProductCategory[];
	details: OrderProductForm[];
	reducer: React.Dispatch<OrderReducerAction>;
}

const ProductList: React.FC<ProductListProps> = (props) => {
	const { categories, details: orderProducts, reducer } = props;

	const [products, setProducts] = useState<Product[]>([]);
	const [categoryId, setCategoryId] = useState(0);
	const [subCategoryIndex, setSubCategoryIndex] = useState(0);

	useEffect(() => {
		const categoriesList = categories[categoryId];
		const index =
			categoriesList.subCategories.length <= subCategoryIndex
				? 0
				: subCategoryIndex;
		const subCategoriesList = categoriesList.subCategories[index];
		setProducts(subCategoriesList.products);
	}, [categoryId, subCategoryIndex, categories, orderProducts]);

	useEffect(() => {
		setSubCategoryIndex(0);
	}, [categoryId]);

	const handleAdd = (id: string) => {
		const options = categories[categoryId].subCategories[
			subCategoryIndex
		].productOptions.filter((po) => po.isDefault);

		let orderProduct = orderProducts.find((d) => d.product.id === id);

		const newProduct = {
			...productDetails,
			registrationDate: new Date(),
			options,
		};

		if (!orderProduct) {
			const p = products.find((p) => p.id === id);
			if (!p) return;

			orderProduct = {
				id: null,
				product: p,
				productPrice: p.price.price,
				productSubTotal: p.price.price,
				quantity: 1,
				details: [newProduct],
				amountToPay: 0,
				unitsToPay: 0,
				paidAmount: 0,
				paidUnits: 0,
				checkboxState: CheckboxStates.Empty,
			};
		} else {
			orderProduct = {
				...orderProduct,
				productSubTotal:
					orderProduct.productSubTotal + orderProduct.productPrice,
				quantity: orderProduct.quantity + 1,
				details: [...orderProduct.details, newProduct],
			};
		}
		reducer({
			type: OrderReducerType.ADD,
			payload: { products: [orderProduct] },
		});
	};

	const getProductQuantity = (productId: string): number => {
		const product = orderProducts.find((p) => p.product.id === productId);
		return product?.quantity || 0;
	};

	return (
		<div className="row my-2">
			<ButtonGroup
				list={categories}
				activeIndex={categoryId}
				setIndex={setCategoryId}
			/>
			<ButtonGroup
				list={categories[categoryId].subCategories}
				activeIndex={subCategoryIndex}
				setIndex={setSubCategoryIndex}
			/>

			{products.map((p) => (
				<div key={p.id} className={"col-12 col-md-4 mb-2"}>
					<div className="card">
						<div className="row g-0">
							<div className="col-7 ps-1 pe-0">
								<span className="card-title">{p.name}</span>
							</div>
							<div className="col-2 px-0">
								<span className="ms-1">{currencyFormat(p.price.price)}</span>
							</div>
							<div className="col-3 px-0 text-end">
								<span className="me-2">{getProductQuantity(p.id)}</span>
								<Button
									mode="secondary"
									size="small"
									onClick={() => handleAdd(p.id)}
									aria-label={`Agregar una unidad de ${p.name.toLowerCase()}`}
								>
									+
								</Button>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default ProductList;
