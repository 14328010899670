import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useLocation, useParams } from "react-router-dom";

import {
	GET_PRODUCT_CATEGORIES_WITH_PRODUCTS,
	GET_ORDER_BY_TABLE_ID,
} from "@/graphql";

import Layout from "./layout";
import { formatDate } from "@/utils/date";
import Loading from "@/components/atoms/loading";
import OrderTemplate from "@/components/templates/order/order";
import { INITIAL_STATE } from "@/utils/order-reducer";
import {
	getOrderTableNumber,
	orderResponseToOrder,
} from "@/utils/order-response-to-order";
import { orderToOrderForm } from "./payment/order-to-order-form";

import { Order, ProductCategory } from "@/interfaces/order.interface";
import { OrderStatus } from "@/interfaces/order-status.enum";
import { Table } from "@/interfaces/table.interface";
import { OrderType } from "@/interfaces/order-type.enum";
import { OrderResponse } from "@/interfaces/order-response.interface";
import { OrderForm } from "@/interfaces/order-payment.interfaces";
import { joinPaymentToPaymentOrder } from "./payment/join-payment-to-order";

interface ProductCategoryResponse {
	productCategories?: ProductCategory[];
}

function onCompleteCallback(
	responseOrder: OrderResponse,
	table: Table | undefined,
): Order {
	if (!responseOrder) {
		const type = table ? OrderType.LOCAL : OrderType.PICKUP;
		const tables = table ? [table] : [];
		const tableNumber = getOrderTableNumber(type, tables);

		return {
			...INITIAL_STATE,
			tables,
			type,
			tableNumber,
		};
	}

	return orderResponseToOrder(responseOrder);
}

export default function OrderPage() {
	const { id: orderId } = useParams();
	const { state } = useLocation();
	const table: Table | undefined = state?.table;
	const [originalOrderId] = useState<string | undefined>(orderId);
	const [order, setOrder] = useState<OrderForm | undefined>(undefined);
	const [categories, setCategories] = useState<ProductCategory[]>([]);

	useEffect(() => {
		if (!table && !orderId) {
			setOrder({ ...orderToOrderForm(INITIAL_STATE) });
		}
	}, [table, orderId]);

	const { loading: orderLoading } = useQuery(GET_ORDER_BY_TABLE_ID, {
		variables: {
			tableId: table?.id,
			orderId: orderId,
			statusCode: OrderStatus.REGISTERED,
			date: formatDate({ format: "en" }),
		},
		fetchPolicy: "no-cache",
		skip: (!table?.id && !orderId) || (!originalOrderId && !!orderId),
		onCompleted: ({ order: responseOrder }: { order: OrderResponse }) => {
			const order = onCompleteCallback(responseOrder, table);
			const orderForm = orderToOrderForm(order);
			const products = joinPaymentToPaymentOrder(
				orderForm.products,
				orderForm.payments,
			);

			setOrder({
				...orderForm,
				products,
			});
		},
	});

	const { loading: categoriesLoading } = useQuery(
		GET_PRODUCT_CATEGORIES_WITH_PRODUCTS,
		{
			onCompleted: (data: ProductCategoryResponse) => {
				if (!data.productCategories) return;
				setCategories(data.productCategories);
			},
		},
	);

	return (
		<Layout>
			{orderLoading || categoriesLoading || !order ? (
				<Loading />
			) : (
				<OrderTemplate order={order} categories={categories} />
			)}
		</Layout>
	);
}
